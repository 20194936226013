import Assets from './assets';
import APIEvents from './events';
import Common from './common';
import NGRProfile from './ngrProfile';
import NGUsr from './ngUsr';
import ThemeMethods from './theme';
import Authentication from './authentication';
import Queries from './queries';
import GQLQueries from './queries-gql';
import Lightbox from './lightbox';
import Path from './path';
import ProxyMethods from './proxy';
import Reporting from './reporting';

const MethodBuilder = function(AppConfig: any) {
    let obj = {
        ...ProxyMethods,
        ...Common,
        ...Path,
        ...Assets,
        ...APIEvents,
        ...Lightbox,
        ...ThemeMethods,
        ...Authentication,
        ...Queries,
        ...GQLQueries,
        ...Reporting,
        ...NGRProfile
    };

    
    if(!('NGUsr' in AppConfig) || AppConfig.NGUsr === true) {
        // need to be able to overwrite methods
        //@ts-ignore
        obj = {...obj, ...NGUsr};
    }
    return obj;
}

export { MethodBuilder }

const AllMethods = {
    ...ProxyMethods,
    ...Common,
    ...Path,
    ...Assets,
    ...APIEvents,
    ...Lightbox,
    ...ThemeMethods,
    ...Authentication,
    ...Queries,
    ...GQLQueries,
    ...Reporting,
    ...NGRProfile,
    ...NGUsr
};

export default AllMethods;