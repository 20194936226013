import { createBrowserHistory } from 'history';

class HistoryTimeline {

    public history: any[] = [];

    constructor() {
        this.loadTimeline();
    }

    push(location: any) {
        this.history.push(location);
        this.saveTimeline();
    }

    lastLocation() {
        return this.getLocationFromIndex(-1);
    }

    getLocationFromIndex(index: number) {
        if(this.history.length === 0) return null;
        return this.history[this.history.length + index];
    }

    loadTimeline() {
        let timeline = []
        try{
            timeline = JSON.parse(window.sessionStorage.getItem('historyTimeline'));
        } catch(e) {};

        this.history = timeline ?? [];
    }

    saveTimeline() {
        window.sessionStorage.setItem('historyTimeline', JSON.stringify(this.history))
    }

    clear() {
        window.sessionStorage.removeItem('historyTimeline');
        this.history = [];
    }

    setCurrentScrollPosition(value: number) {
        this.history[this.history.length - 1].scrollPosition = value;
    }

    getPreviousScrollPosition() {
        let current = this.history[this.history.length - 1];
        let previousLoad = [...this.history].reverse().find((h,i) => i > 0 && h.pathname === current.pathname && h.hash === current.hash);
        let sp = previousLoad?.scrollPosition ?? 0
        if(current?.action === "LOAD") sp = 0;
        return sp;
    }

};

const historyTimeline = new HistoryTimeline();

export { historyTimeline };
const builtHistory = {...createBrowserHistory(), timeline: historyTimeline };
export default builtHistory;