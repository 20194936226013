export default class CustomPromise {

    // Internal info
    promiseState = null;
    promiseArg = null;

    // Internal functions
    setCallback = () => {};
    setPromise = () => {};

    // Calbacks
    callbacks = {};

    callState = null;
    resolve = null;
    reject = null;
    done = null;
    fail = null;
    when = null;

    constructor() {
        // Functions to set how the callback should be executed
        this.callState = function(state, arg){
            return this.setPromise(state, arg);
        };
        this.resolve = function(arg){
            return this.setPromise('DONE', arg);
        };
        this.reject = function(arg){
            return this.setPromise('FAIL', arg);
        };

        // Functions to set the callback functions
        this.done = function(arg){
            return this.setCallback('DONE', arg);
        };
        this.fail = function(arg){
            return this.setCallback('FAIL', arg);
        };
        this.when = function(state, callback){
            return this.setCallback(state, callback);
        };
        this.pipe = function(arg){
            return this.setCallback('DONE', arg);
        };

        // for js promises
        this.then = function(arg) {
            return this.setCallback('DONE', arg);
        }
        this.catch = function(arg) {
            return this.setCallback('FAIL', arg);
        }
        this.finally = function(arg) {
            return this.setCallback('DONE', arg);
        }


        // jQuery Deferred values

        // always: ƒ ()
        // done: ƒ ()
        // fail: ƒ ()
        // notify: ƒ ()
        // notifyWith: ƒ ( context, args )
        // pipe: ƒ ( /* fnDone, fnFail, fnProgress */ )
        // progress: ƒ ()
        // promise: ƒ ( obj )
        // reject: ƒ ()
        // rejectWith: ƒ ( context, args )
        // resolve: ƒ ()
        // resolveWith: ƒ ( context, args )
        // state: ƒ ()
        // then: ƒ ( /* fnDone, fnFail, fnProgress */ )


        // JS Promise

        // catch: ƒ catch()
        // constructor: ƒ Promise()
        // finally: ƒ finally()
        // then: ƒ then()
    }


    setPromise = function(state, arg) {

        // Set information that it is resolved already
        this.promiseState = state;
        this.promiseArg = arg;

        // Unset the resolve and reject function to avoid recalls
        this.resolve = null;
        this.reject = null;

        // Call the done callback if already informed
        if( typeof this.callbacks[state] === 'function' ) this.callbacks[state](arg);

        // Return all functions
        return this;
    };
    setCallback = function(state, callback) {
        // Escape in case the argument is not a valid function or a different promise was already set
        if (typeof callback !== 'function' ||
            (typeof promiseState === 'string' && this.promiseState !== state)) return this;

        // Set callback info to be used when it's resolved
        this.callbacks[state] = callback;

        // Execute the callback function it was already resolved
        if(typeof promiseState === 'string') callback(this.promiseArg);

        // Return the function
        return this;
    };

};