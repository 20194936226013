
const ReportingMethods: any = {
    fdlReportingUser: null,

    saveReportingEvent: async function(data: any = {}): Promise<any> {
        try {
            let user = await this.checkUserAuth();
            if(!this.isLoggedIn() || !user) return null;

            if(this.AppConfig && 'FDLEventUrl' in this.AppConfig) {

                // need to add in hash if it's part of the
                let currentPath = this.getCurrentLocation().pathname.replace(this.rootPath, '').replace(/\/$/, "");

                if(currentPath === "") currentPath = "home";

                if(!this.fdlReportingUser) {
                    let profile = null;

                    try {
                        if(!("NGUsr" in this.AppConfig) || this.AppConfig.NGUsr === true) {
                            profile = await this.getCurrentUser('fdl');
                            if(profile) this.fdlReportingUser = 'username' in profile && profile.username && profile.username !== "" ? profile.username : profile.userID;
                            else this.fdlReportingUser = null;
                        } else {
                            profile = await this.getCurrentNGRProfile(['fdrURI']);
                            if(profile) this.fdlReportingUser = 'fdrURI' in profile && profile.fdrURI && profile.fdrURI !== "" ? profile.fdrURI : profile.id;
                            else this.fdlReportingUser = null;
                        }
                    } catch(e) {
                        this.fdlReportingUser = null;
                    }
                }

                let body = {
                    partition: this.getPartition(),
                    package: Object.values(this.getProxyModuleData()).filter(v => v !== null).join('/').replace(/\/$/, ""),
                    navigation: currentPath, // current path
                    nav_name_path: '', // future path
                    nav_label_path: '',
                    activity: '',
                    user: this.fdlReportingUser,
                    bundle: this.getCurrentLocation().host,
                    ...data
                }

                try {
                    fetch(this.AppConfig.FDLEventUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(body)
                    })
                    return true;
                } catch(e) {
                    return null;
                }
            }
        } catch(e) {
            console.error('Error in saveReportingEvent', e)
            return null;
        }
        return null;
    }
}

export default ReportingMethods;