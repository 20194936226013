import { create } from 'twind';
import { createObserver } from 'twind/observe';
import twconfig, { customSheet, parseTWColors } from '../utils/twconfig';
import { hexToRgba, hexToRgbArray } from '../utils/functions';

const ThemeMethods = {
    getTheme: function() {
        return JSON.parse(JSON.stringify(this.theme));
    },

    getMuiTheme: function() {
        return this.muiTheme;
    },

    isWidthDown: function(key: string, top?: boolean) {
        if(!this.muiTheme) return false;
        let frame: any = this.getProxyWindow().contentWindow;
        if(top) frame = window;
        let match = frame.matchMedia(`(max-width: ${this.muiTheme.breakpoints.values[key]}px)`);
        return (match) ? match.matches : false;
    },

    isWidthUp: function(key: string, top?: boolean) {
        if(!this.muiTheme) return false;
        let frame: any = this.getProxyWindow().contentWindow;
        if(top) frame = window;
        let match = frame.matchMedia(`(min-width: ${this.muiTheme.breakpoints.values[key]}px)`);
        return (match) ? match.matches : false;
    },

    isWidthBetween: function(start: string, end: string, top?: boolean) {
        if(!this.muiTheme) return false;
        return this.isWidthUp(start, top) && this.isWidthDown(end, top);
    },

    getWidthKey: function(top?: boolean) {
        if(!this.muiTheme) return false;
        let key = 'xs';
        this.muiTheme.breakpoints.keys.some((k: string) => {
            if(this.isWidthUp(k, top)) {
                key = k;
                return false;
            } else {
                return true;
            }
        });
        return key;
    },

    applyTWTheme: async function(key: string, restrictToModule: boolean = false) {

        let twtheme = this.themeList.tailwind;

        if(!Object.keys(twtheme.sheets).length) return;

        
        if(!(key in twtheme.sheets)) {
            console.error(`Theme not found: ${key}`)
            return;
        }


        if(restrictToModule === false) {
            this.themeList.tailwind.currentTheme = key;
            this.callEvent('theme_change', this.themeList);
        } else {
            let frame = this.getProxyWindow();
            let doc = 'contentWindow' in frame ? frame.contentWindow.document : frame.document;
            let head = doc.getElementsByTagName('head')[0];
            let themeStyleID = "__ng_theme";
            let themeApplied = doc.getElementById(themeStyleID);

            if(themeApplied) {
                head.removeChild(themeApplied)
            }
            var style = doc.createElement('style');
            style.id = themeStyleID;
            style.innerHTML = twtheme.sheets[key];
            head.appendChild(style);
        }
    },

    initTWTheme: function() {
        // create tw sheet
        if('tailwind' in this.themeList) {
            // let fw = this.getProxyWindow().contentWindow;
            let frame = this.getProxyWindow();
            let fw = 'contentWindow' in frame ? frame.contentWindow : frame;
            let targetDoc: any = fw.document;
            let stylesheetID: string = "__twind";
            let head = targetDoc.getElementsByTagName('head')[0];
            var styleTag = document.createElement('style');

            if(targetDoc.getElementById(stylesheetID)) {
                head.removeChild(targetDoc.getElementById(stylesheetID))
            }
            
            styleTag.id = stylesheetID;
            styleTag.appendChild(document.createTextNode(""));

            
            head.appendChild(styleTag);

            const sheet = customSheet(targetDoc.getElementById(stylesheetID).sheet);

            let instance = create({
                sheet,
                ...twconfig,
                ...parseTWColors(this.themeList.tailwind.config)
            });

            let observer = createObserver(instance);
            observer.observe(targetDoc.body);

            fw.nexgen.on('theme_change', (themeList: any) => {
                fw.nexgen.applyTWTheme(this.getTWTheme(), true);
            });

            fw.nexgen.applyTWTheme(this.getTWTheme(), true);

        }
    },

    getTWTheme: function() {
        return this.themeList.tailwind.currentTheme;
    },

    getTWThemeValue: function(name: string, options: any = {}): any {
        options =  { opacity: 1, type: 'rgba', ...options };
        let theme = this.themeList.tailwind.themeSets.find((t: any) => t.name === this.themeList.tailwind.currentTheme);
        let twCurrent = theme.palette;
        let key = Object.keys(twCurrent).find(k => name.match(k));
        let entry: any = Object.entries(twCurrent[key]).find(([k,v]: any) => k.match(name));

        if(entry) {
            switch(options.type) {
                case 'rgba':
                    return hexToRgba(entry[1], options.opacity);
                case 'rgb':
                    return 'rgba(' + hexToRgbArray(entry[1]).join(',') + ')'
                case 'rgbArray':
                    return hexToRgbArray(entry[1]);
                case 'hex':
                    return entry[1]
            }
        }

        return null;
    },

    listTWThemeNames: function() {
        return Object.keys(this.themeList.tailwind.sheets);
    },

    listTWThemes: function() {
        return this.themeList.tailwind.sheets;
    },

    getDefaultTWProps: function(type: string) {
        if(this.themeList.tailwind?.defaultProps && type in this.themeList.tailwind?.defaultProps) {
            return this.themeList.tailwind.defaultProps[type];
        } else {
            return {}
        }
    }

}

export default ThemeMethods;