export type Observable = {
  [key: string]: {
    subscriptions: any[],
    subscribe_keys: string[],
    query: any
  }
}

export interface Options {
  width?: number | string
  height?: number | string
  overlay?: string
  overlayOpacity?: number
  id?: string
  params?: {[key: string]: any}
}

export interface LightboxOptions extends Options {
  url?: string
  module?: string
  isProfile?: boolean
  styles?: {
      background?: GenericObject
      box?: GenericObject
  }
  options?: GenericObject
}

export type GenericObject = {[key: string]: string | number | boolean} 

export type PlayerFrame = {
  id: string
  url?: string
  styles?: Options
  backgroundStyles?: Object
  name?: string
  params?: {[key: string]: any}
  options?: GenericObject
}

export type OpenWindowProps = {
  url?: string
  module?: string
  id?: string
  values?: {
    [key: string]: string
  }
}

export type CloseWindowProps = {
  module: string
}


export type GetAssetResult = {
  [key: string]: any
}

export type GetAssetOptions = {
  type?: string
  headers?: any
}

export type Upload = {
  file: File
  filename: string
  level?: string
}

export type FrameMessage = {
  frame: any;
  msg: any;
}


export type LoginOptions = {
  setSignedIn?: boolean
  nextView?: string
  returnProfileValues?: string[]
}

export enum MFAType {
  "SMS_MFA" = "SMS_MFA",
  "SOFTWARE_TOKEN_MFA" = "SOFTWARE_TOKEN_MFA"
}

export type NewPasswordFields = {
  email?: string
  phone_number?: string
}

export type GetAsset = {
  asset?: KeyParams
  assets?: KeyParams[]
}

export type KeyParams = {
  [key: string]: string | boolean
  filename?: string
  name?: string
  type?: string
}

export type RunQuery = {
  query: string
  variables?: any
  subscribe?: boolean
  id?: string
}

export type ToggleHeader = {
  show: boolean
}


export type GetStoreValues = {
  module: string
  id: string
  key?: string
}

export type UserAttributes = {
  email?: string
  phone_number?: string
  given_name?: string
  family_name?: string
  nickname?: string
}

export type InitModule = {
  name?: string
  id?: string
  currentModule?: string
  moduleID?: string
  key: string
}

export type ConfigOptions = {
  strict?: boolean
}

export type UploadOptions = {
  level?: string
  filename?: string
  uploadPath?: string
  uploadProgress?: Function
  mediaID?: string
}

export type RunOptions = {
  variables?: {[key: string]: any}
  subscribe?: Function
  subscribe_keys?: string[] | string
  fetchPolicy?: any
  callback?: Function
  subscription?: SubscriptionOptions
}

export type SubscriptionOptions = {
    id?: string
    query?: any
    subscription?: any
    callback: Function
    variables?: {[key: string]: any}
    fetchPolicy?: any
}

export type ProxyModuleData = {
    id?: string
    name?: string
}

export enum EventList {
  ERROR = "error",
  SIGNIN = 'signin',
  SIGNOUT = 'signout',
  CREATED = 'profile_created',
  CHANGE = 'route_change',
  ROUTE_REPLACE = 'route_replace',
  THEME = 'theme',
  UPLOADED = 'uploaded',
  MEDIA_CAPTURE = 'media_capture',
  LAYOUT = 'layout_change',
  LAYOUT_STATE = 'layout_state_change',
  BLOCK = 'block_change'
}

export type MediaCaptureType = {
    uploadOnSave?: boolean
    captureCallback?: Function
    uploadCallback?: Function
    module?: string
    id?: string
    lightboxStyles?: {
      background?: GenericObject
      box?: GenericObject
    }
    options?: GenericObject
}

export type Events = {
    [key in EventList]: { // event (ex. signout, signin)
        // [key: string]: { // module name
        //     [key: string]: Object // id and module object
        // }
        [key: string]: HTMLFrameElement // module key and module object
    }
}

export type NavigateOptions = {
    external?: boolean
    newTab?: boolean
    state?: any
    updatePath?: boolean
    layoutState?: string
}

export interface NavigateModuleOptions extends NavigateOptions {
    id?: string
    navigateModule?: boolean
}