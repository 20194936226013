import { Auth } from 'aws-amplify';

export const TokenRefresher = async () => {
    try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = cognitoUser.signInUserSession;
        let idTokenExpire = currentSession.getIdToken().getExpiration();
        let now = Math.trunc(new Date().getTime() / 1000);
        let expiresIn = idTokenExpire - now;
        let refreshTime = expiresIn - 300;
        refreshTime = refreshTime < 0 ? 0 : refreshTime;
        setTimeout(async () => {
            let newCognitoUser = await Auth.currentAuthenticatedUser({
                bypassCache: true,
            });
            await window.nexgen.setClient(newCognitoUser);
            console.log(
                `Tokens refreshed! Next refresh in: ${Math.floor(
                    refreshTime / 60
                )}m ${refreshTime % 60}s`
            );
            TokenRefresher();
        }, refreshTime * 1000);
    } catch (e) {
        console.log('Unable to refresh Token', e);
    }
};
