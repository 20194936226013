import { ProxyModuleData } from '../types/api.types';
import { v4 } from 'uuid';

export default class NexgenInstance {
    name: string = null;
    id: string = null;
    window: HTMLFrameElement | Window = null;
    uuid: string = null;

    constructor(frame: HTMLFrameElement | Window, instanceID?: string) {
        this.name = frame.name;
        this.id = (frame as HTMLFrameElement).id;
        this.window = frame;
        
        if(instanceID) this.uuid = instanceID
        else if(frame.hasOwnProperty('getAttribute')) {
            this.uuid = (frame as HTMLFrameElement).getAttribute('data-playerid')
        } else {
            this.uuid = v4();
        }
    }

    getProxyInstanceID(): string {
        return this.uuid;
    }

    getProxyWindow(): HTMLFrameElement | Window {
        return this.window;
    }

    getProxyModuleData(): ProxyModuleData {
        return {
            name: this.name,
            id: this.id
        }
    }

    getProxyModuleID(): string {
        let md = this.getProxyModuleData();
        return`${md.name}${'id' in md && md.id && md.id !== md.name ? '.' + md.id : ''}`;
    }
}