const event_utils = {
  error: {},
  signin: {},
  signout: {},
  profile_created: {},
  route_change: {},
  route_replace: {},
  theme: {},
  theme_change: {},
  uploaded: {},
  media_capture: {},
  layout_change: {},
  layout_state_change: {},
  block_change: {}
}
export default event_utils;