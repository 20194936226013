import React from 'react';

import {
    PlayerFrame
} from '../types/api.types';

import {
    Close
} from '@material-ui/icons';

interface LightboxesProps {
    frames: any[],
    themeList: any
    frameLoaded: Function
}

const Lightboxes = (props: LightboxesProps) => {

    let { frames, themeList, frameLoaded } = props;

    return <>
            {frames.map((f: PlayerFrame) => {
                    let params = {isLightbox: true};
                    if(f.params) params = {...params, ...f.params};
                    console.log(f.styles)
                    return <React.Fragment key={f.id + '-lightbox'}>
                        <div key={f.id + '-lightbox-bg'} className="lightbox-bg" style={f.backgroundStyles}></div>
                        <div className='lightbox-frame-wrapper' style={f.styles}>
                            {'options' in f && typeof f.options !== 'undefined' && 'closeButton' in f.options && f.options.closeButton === false ? null :
                                <div className='close-lightbox' onClick={() => {
                                    window.nexgen.closeLightbox(f.id);
                                }} style={{
                                    background: themeList.mui?.build?.palette.status.solid
                                }}>
                                    <Close fill={themeList.mui?.build?.palette.primary.main} />
                                </div>
                            }
                            <iframe title={f.id} key={f.id} id={f.id} name={f.name} className='lightbox' src={f.url} onLoad={(e) => {
                                frameLoaded(null, {params}, e)
                            }} ></iframe>
                        </div>
                    </React.Fragment>
                })
            }
            </>
}

export default Lightboxes;