//Pre Loader component version 1
import React, { useState, useEffect } from 'react';


const PreLoader = () => {
    let palette = "primary", paletteStop = "500", useLoadIcon = false;
    if ('palette' in window.nexgen.AppConfig) {
         palette = window.nexgen.AppConfig.palette;
    }
    if ('paletteStop'  in window.nexgen.AppConfig) {
         paletteStop = window.nexgen.AppConfig.paletteStop;
    }
    if (('useLoadIcon' in window.nexgen.AppConfig)) {
         useLoadIcon = window.nexgen.AppConfig.useLoadIcon;
    }
    
    let themeColr = window.nexgen.getTWThemeValue(palette+"-"+paletteStop);


    const useImageError = () => {
        const [el, setEl] = useState(null); // contains image reference
        const [error, setError] = useState(false); // contains error flag
      
        const _handleError = () => { setError(true); }  // set error
        const retry = () => { setError(false); } // set error false to img can re-render
      
        useEffect(() => {
          // use of error event of the image tag
          el?.addEventListener("error", _handleError);
      
          return () => {
            el?.removeEventListener("error", _handleError);
          }
        }, [el]);
      
        return [
          setEl, // set the image ref
          error, // error flag
          retry, // a func, which can be used to re-render image
          el // img ref(for special case which requires ref)
        ];
      };
let preloaderSvg = "/assets/protected/images/loader.svg";
const [setImg, hasError, retry, imgRef] = useImageError();

const preloaderStyle = {
    borderTopColor: themeColr
};
if(!useLoadIcon) return null;

  if (hasError)     return (
<div className="loader-container">
  <div
    style={preloaderStyle}
    className="
      loader
      ease-linear
      rounded-full
      border-8 border-t-8
      h-32
      w-32
    "
  >
</div>
</div>
    );
    return (
        <div className="loader-container">
                <img ref={setImg} src={preloaderSvg} alt="Preloader" />
        </div>
      );    
};

export default PreLoader;