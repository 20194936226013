import { EventList } from '../types/api.types';
import baseEvents from '../utils/events';

const EventMethods = {

    events: baseEvents,

    callEvent: function(event: EventList, data: any) {
        try {
            for (let c in this.events[event]) {
                let frame: Window = this.events[event][c];
                if(frame) {
                    const custom = new CustomEvent(event, { detail: data });
                    // console.log('call event', event, c);
                    frame.document.dispatchEvent(custom);
                }
            }
        } catch (err) {
        }
    },

    on: function(event: EventList, cb: Function) {
        let contextKey: string = this.getProxyInstanceID();
        let frame: HTMLFrameElement = this.getProxyWindow();
        let frameWindow: Window = window;

        if(frame && frame.contentWindow) {
            frameWindow = frame.contentWindow;
        }
        
        frameWindow.document.addEventListener(event, (data: any) => {
            cb(data.detail);
        });
        if(!(contextKey in this.events[event])) {
            this.events[event][contextKey] = frameWindow;
        }

        return true;
    },

    remove: function(event: EventList) {
        try {
            let contextKey = this.buildContextKey();
            if (contextKey !== "" && contextKey in this.events[event]) delete this.events[event][contextKey];
            return true;
        } catch (e) {
            return false;
        }
    },

    unload: function(contextKey: string) {
        let e: any;
        for (e in this.events) {
            try {
                if (contextKey !== "" && contextKey in this.events[e]) {
                    this.events[e][contextKey] = null;
                }
            } catch (err) {
                // no listener found for this event on this module
                console.log('unload error', contextKey)
            }
        }
    }
};

export default EventMethods