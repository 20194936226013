import { NGUsrType } from '../types/methods';
const NGUserMethods: NGUsrType = {

    // getCurrentNGRProfile: async function(values: string[], subscribe?: Function) {
    //     let originalValues = [...values];

    //     console.log('original values', originalValues);

    //     let searchValues =

    //     let ngprofile = await this.getCurrentProfile(values.join(' '), subscribe);

    //     console.log('ngprofile', ngprofile);
    // },

    NGUser: {},
    NGPersFDLUser: {},
    fdlsubs: [],
    ngusrsubs: [],

    fullNGUsrQuery: `
        id
        createdAt
        updatedAt
        expiresAt
        ngrState
        attStandard {
            themePreference
            developer
            privacyPolicyAccepted
        }
        attPublic {
            firstName
            lastName
            title
            profileImage
        }
        attComms {
            email
            phoneNumber
        }
    `,

    // need to define the mutation result
    fullNGUsrMutationResult: `
        id
        attStandard {
            userID
            themePreference
            developer
            privacyPolicyAccepted
        }
        attPublic {
            userID
            firstName
            lastName
            title
            profileImage
        }
        attComms {
            userID
            email
            phoneNumber
        }
    `,

    fullNGPersFDLUserQuery: `
        id
        userID
        createdAt
        updatedAt
        username
        partition
        rosterType
        firstName
        lastName
        email
        attFDLUser {
            personaID
            username
            partition
            rosterType
            firstName
            lastName
            email
            phone
            staffID
            buName
            groupName
            role
            managerName
            trainingStartDate
            regionCode
            regionDescription
            districtCode
            districtDescription
            territoryCode
            territoryDescription
            flags
        }
    `,

    getCurrentFDLUserProfile: async function(values: string = this.fullNGPersFDLUserQuery, subscribe?: Function) {
        console.warn('getCurrentFDLUserProfile is deprecated. Update method to getCurrentNGPersFDLUser');
        return await this.getCurrentNGPersFDLUser(values, subscribe);
    },
    getCurrentNGPersFDLUser: async function(values: string = this.fullNGPersFDLUserQuery, subscribe?: Function) {
        return await this.run(`
            query getCurrentNGPersFDLUser {
                getCurrentNGPersFDLUser {
                    ${values}
                }
            }
            `,
            {
                // subscribe_keys: values,
                // subscribe: subscribe ? subscribe : null
                subscription: subscribe ? {
                    query: `
                        onUpdateNGPersFDLUser { ${this.fullNGPersFDLUserQuery} }
                    `,
                    callback: (data: any) => {
                        console.log('FDL User Profile update', data)
                        // if(Object.keys(this.NGUser).length) this.NGUser = {...this.NGUser, ...data};
                        // else this.NGUser = data;
                        // subscribe(data)
                    }
                } : null
            }
        );
    },

    getFDLUserProfile: async function(id?: string, values: string = this.fullNGPersFDLUserQuery, subscribe?: Function) {
        console.warn('getFDLUserProfile is deprecated. Update method to getNGPersFDLUser');
        return await this.getNGPersFDLUser(id, values, subscribe);
    },

    getNGPersFDLUser: async function(id?: string, values: string = this.fullNGPersFDLUserQuery, subscribe?: Function) {
        if(!id) return await this.getCurrentNGPersFDLUser(values, subscribe);

        return await this.run(`
            query getNGPersFDLUser($id: ID!) {
                getNGPersFDLUser(id: $id) {
                    ${values}
                }
            }
            `,
            {
                subscribe_keys: values,
                subscribe: subscribe ? subscribe : null,
                variables: {
                    id
                }
            }
        );
    },


    getCurrentNGUserProfile: async function (values: string = this.fullNGUsrQuery, subscribe?: Function) {
        console.warn('getCurrentNGUserProfile has been deprecated. Update method to getCurrentNGUsr');
        return await this.getCurrentNGUsr(values, subscribe);
    },

    getCurrentNGUsr: async function (values: string = this.fullNGUsrQuery, subscribe?: Function) {
        return await this.run(`
            query getCurrentNGUsr {
                getCurrentNGUsr {
                    ${values}
                }
            }
            `,
            {
                subscription: subscribe ? {
                    query: `
                        onMutateNGUsr { ${this.fullNGUsrMutationResult} }
                    `,
                    callback: (data: any) => {
                        if(data.id === this.NGUser.id) {
                            this.NGUser = {...this.NGUser, ...data};
                            subscribe(data)
                        }
                    }
                } : null

            }
        );
    },

    getNGUsr: async function (id?: string, values: string = this.fullNGUsrQuery, subscribe?: Function) {

        if(!id) return await this.getCurrentNGUsr(values, subscribe);


        return await this.run(`
            query getNGUsr($id: ID!) {
                getNGUsr(id: $id) {
                    ${values}
                }
            }
            `,
            {
                subscribe_keys: values,
                subscribe: subscribe ? subscribe : null,
                variables: {
                    id
                }
            }
        );
    },

    listNGUsrs: async function (options: {filter?: any, limit?: number} = {}, values: string = this.fullNGUsrQuery, subscribe?: Function) {

        let types: any = {
            'filter': 'ModelNGUsrFilterInput',
            'limit': 'Int'
        }

        let variables: any = {
            limit: options && 'limit' in options ? options.limit : 1000
        }
        if('filter' in options) variables.filter = options.filter;

        return await this.run(`
            query listNGUsrs(${Object.keys(variables).map(key => `$${key}: ${types[key]}`).join(' ')}) {
                listNGUsrs(${Object.keys(variables).map(key => `${key}: $${[key]}`).join(' ')}) {
                    items {
                        ${values}
                    }
                }
            }
            `,
            {
                subscribe_keys: values,
                subscribe: subscribe ? subscribe : null,
                variables
            }
        );
    },

    getCurrentProfile: async function (type: string | boolean = 'both', subscribe?: Function) {
        console.warn('getCurrentProfile is deprecated. Update method to getCurrentUser.');
        return await this.getCurrentUser(type, subscribe);
    },

    getCurrentUser: async function (type: string | boolean = 'both', subscribe?: Function) {
        
        if(type === true) type = 'fdl';
        if(type === false) type = 'ngusr';

        
        if(type === 'fdl' || type === 'both') {
            if(this.NGPersFDLUser === undefined || this.NGPersFDLUser === null || Object.keys(this.NGPersFDLUser).length === 0) {
                this.NGPersFDLUser = await this.getCurrentNGPersFDLUser(this.fullNGPersFDLUserQuery, subscribe);
            } else {
                if(subscribe) {
                    this.getCurrentNGPersFDLUser(this.fullNGPersFDLUserQuery, subscribe);
                }
            }
            if(type !== 'both') return this.NGPersFDLUser;
        }
        
        if(type === 'ngusr' || type === 'both') {
            if(this.NGUser === undefined || this.NGUser === null || Object.keys(this.NGUser).length === 0) {
                this.NGUser = await this.getCurrentNGUsr(this.fullNGUsrQuery, subscribe);
            } else {
                if(subscribe) {
                    this.getCurrentNGUsr(this.fullNGUsrQuery, subscribe);
                }
            }
            if(type !== 'both') return this.NGUser;
        }

        return {...this.NGUser, ...this.NGPersFDLUser}

    },


    updateUserProfile: async function(data: any) {
        console.warn('updateUserProfile is deprecated. Update method to updateCurrrentNGUsrAtt');
        return await this.updateCurrentNGUsrAtt(data);
    },

    updateCurrentNGUsrAtt: async function(data: any) {
        try {
            return await this.run(`
                mutation updateCurrentNGUsrAtt($input: UpdateCurrentNGUsrAttInput!) {
                    updateCurrentNGUsrAtt(input: $input) { ${this.fullNGUsrMutationResult} }
                }
                `,
                {
                    variables: {input: data}
                }
            );
        } catch(e) {
            console.error(e);
            return null;
        }
    }

}

export default NGUserMethods;

