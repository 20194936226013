export const fullNGUsrQuery = `
        id
        createdAt
        updatedAt
        expiresAt
        ngrState
        attStandard {
            themePreference
            developer
            privacyPolicyAccepted
        }
        attPublic {
            firstName
            lastName
            title
            profileImage
        }
        attComms {
            email
            phoneNumber
        }
    `;

    // need to define the mutation result
export const fullNGUsrMutationResult = `
        id
        attStandard {
            userID
            themePreference
            developer
            privacyPolicyAccepted
        }
        attPublic {
            userID
            firstName
            lastName
            title
            profileImage
        }
        attComms {
            userID
            email
            phoneNumber
        }
    `;

export const fullNGPersFDLUserQuery = `
        id
        userID
        createdAt
        updatedAt
        username
        partition
        rosterType
        firstName
        lastName
        email
        attFDLUser {
            personaID
            username
            partition
            rosterType
            firstName
            lastName
            email
            phone
            staffID
            buName
            groupName
            role
            managerName
            trainingStartDate
            regionCode
            regionDescription
            districtCode
            districtDescription
            territoryCode
            territoryDescription
            flags
        }
    `;

export const NGRProfile = `
    id
    createdAt
    updatedAt
    expiresAt
    ngrState
    cognitoUserID
    cognitoUsername
    fdrPartition
    fdrURI
    themePreference
    developer
    email!
    phoneNumber
    firstName
    lastName
    title
    profileImage
    fdrFlags
`

export const listNGOuCtxValues = `
query listNGOuCtxValues {
    listNGOuCtxValues(filter:{ctxKey:{beginsWith:"defaultProps."}}) {
        items {
        ctxKey
        ctxValue
        }
    }
}`

export const listUserEvents = `
    query listUserEvents($userId: ID!) {
        listUserEvents(input:{
            userId: $userId
        }){
                __typename
            id
            userId
            sessionUserId
            sessionId
            user{
                id
                firstName
                lastName
            }
            sessionUser{
                id
                firstName
                lastName
            }
            session {
                id
                sessionToken
            }
            partition
            ngModule
            ngModuleHash
            context
            startsAt
            endsAt
            timeSpent
            action
            navigation
            navigationPath
            playerMeta
            ngModuleMeta    
            ... on UserEventLearningJourney {
            itemId
            item{
                title
            }
            itemDetailOutput{
                itemId
                title
            }
            itemRef
            title
            flags
            itemType
            progress
            status
            }
            ... on UserEventNGRLaunchpad{
            title
            }
            ... on UserEventChecklist {
            listId
            itemId
            assignmentId
            status
            }
        }
    }
`;

export const processUserEventGeneric = `
    mutation processUserEventGeneric ($input: processUserEventGenericInput!) {
        processUserEventGeneric(input:$input){
            success
            message
            userEventGeneric{
            id
            userId
            user{
                id
                firstName
                lastName
            }
            sessionId
            session{
                id
                sessionToken
                expiresAt
            }
            sessionUserId
            sessionUser{
                id
                firstName
                lastName
            }
            partition
            ngModule
            ngModuleHash
            context
            startsAt
            endsAt
            timeSpent
            action
            navigation
            navigationPath
            playerMeta
            ngModuleMeta
            }
        }
    }
`;
