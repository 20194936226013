import { listNGOuCtxValues } from './queryStrings';

export function Uint8ToString(arr: Uint8Array) {
    let str = "";
    for (var i=0; i<arr.byteLength; i++) {
      str += String.fromCharCode(arr[i]);
    }
    return str;
}

export async function getConfig(rootPath: string) {
  let resconfig = await fetch(`${rootPath}/config.json`);
  let config = await resconfig.json();
  return config;
}

export function isObject(item: any) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export const mergeDeep = (target: {[key: string]: any}, ...sources: any): {} => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if(source[key] !== null) {
        if (isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const cloneObject = (source: any) => {
  let clone: any;

  if (Object.prototype.toString.call(source) === '[object Array]') {
      clone = [];
      for (var i=0; i<source.length; i++) {
          clone[i] = cloneObject(source[i]);
      }
      return clone;
  } else if (typeof(source)=="object") {
      clone = {};
      for (var prop in source) {
          if (source.hasOwnProperty(prop)) {
              clone[prop] = cloneObject(source[prop]);
          }
      }
      return clone;
  } else {
      return source;
  }
}

export const getPartitionType = (location: any, ngr: boolean = false) => {
    let partition = ngr ? 'production' : 'release';
    if(location.host.match(new RegExp(/sandbox/gi)) || location.host.match(new RegExp(/ide/gi))) {
        partition = ngr ? 'sandbox' : 'sandbox';
    }
    if(location.host.match(new RegExp(/staging/gi))) {
        partition = ngr ? 'staging' : 'test';
    }
    return partition;
}


export const parsePartition = (string: string, partition: string, ngrPartition?: string) => {
    return string.replace(new RegExp(/{fdr_partition}/gi), partition)
                .replace(new RegExp(/{partition}/gi), partition)
                .replace(new RegExp(/{ngr_partition}/gi), ngrPartition);
}

export const getDefaultProps = async () => {
    let obj: any = {};
    let props = await window.nexgen.run(listNGOuCtxValues);
    if(props?.items.length) {
        props.items.forEach((item: any) => {
            obj[item.ctxKey.replace('defaultProps.', '')] = JSON.parse(item.ctxValue);
        })
    }
    return obj;
}
export const parseValues = (string: string, replacements: {[key: string]: string}) => {
    for(var r in replacements) {
        string = string.replace(new RegExp(`{${r}}`, 'gi'), replacements[r]);
    }
    return string;
}

export const hexToRgba = (hex: string, alpha: number = 1) => {
    let arr = hexToRgbArray(hex);
    if(arr) {
        return 'rgba(' + arr.join(',') + ',' + alpha + ')';
    } else return null;
}

export const hexToRgbArray = (hex: string) => {
    var c: any;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return [(c>>16)&255, (c>>8)&255, c&255];
    }
    return null;
}
