import { matchPath } from 'react-router';
import history from '../utils/history';
import { NavigateOptions, NavigateModuleOptions } from '../types/api.types';
import { callbackify } from 'util';
import { processUserEventGeneric } from '../utils/queryStrings';

const PathMethods = {

    modulePaths: {},

    setNavPath: function(path: string) {
        this.navPath = path;
    },

    setModuleHashList: function() {
        if(this.getLocationHash() !== "") {
            let hashes = this.getLocationHash().replace(/^(#)/,'').split('&').map((h: string) => h.replace(/^\//,'').replace(/\/$/,''));
            hashes.forEach((hash: string) => {
                let findMID = hash.match(/([^/]*)\//);
                if(findMID && findMID.length > 1) {
                    let mid = findMID[1];            
                    let url = hash.replace(new RegExp("^" + mid), '');
                    this.modulePaths[mid] = url.replace(/^\//, '').replace(/\/$/, ''); 
                }
            });
        }
    },

    navigate: async function(url: string, external: boolean | Object = false, newTab: boolean = false, navOptions: Object = {}) {
        let options: NavigateOptions = navOptions;

        if(typeof external === 'object') {
            options = external;
        } else {
            options.external = external;
            options.newTab = newTab;
        }

        
        let navTo = url.replace(new RegExp("^" + this.navPath), '').replace(/\/$/, "").replace(/^\//,'');
        // in case it's only set to /player in the url
        navTo = navTo.replace(new RegExp("^" + this.rootPath), '').replace(/\/$/, "").replace(/^\//,'');
        // if(navTo === "") navTo = 'home';
        
        // console.log('calling navigate reporting event')
        await this.saveReportingEvent({
            activity: "navigate",
            nav_name_path: url.replace(new RegExp("^" + this.rootPath), '').replace(/\/$/, "")
        });
        
        let historyMethod = history.push;
        if(options?.updatePath) {
            historyMethod = history.replace;
        }

        if (url === '/home' || url === '/') {
            if(newTab) {
                url === '/home' ? window.open(`${this.navPath}/`, '_blank') : window.open(`/`, '_blank');
            } else {
                url === '/home' ? historyMethod(`${this.navPath}/`, options.state) : historyMethod(`/`, options.state);
            };
        }

        let currentUserPath = await window.nexgen.getCurrentLocation().pathname;
        let userData = await window.nexgen.getCurrentUser('fdl');

        if (currentUserPath === "") currentUserPath = "home";
        
        // if (userData !== undefined && window.nexgen.authUserObject.attributes !== null) {
            let mutationVariables: any = {
                input: {
                    // userId: userData.userID,
                    // sessionUserId: userData.userID,
                    // sessionId: window.nexgen.authUserObject.attributes.sub ? window.nexgen.authUserObject.attributes.sub : "",
                    partition: window.nexgen.getPartition(),
                    ngModule: "ngr-player",
                    startsAt: new Date(),
                    action: "navigate",
                    navigation: currentUserPath,
                    navigationPath: url.replace(new RegExp("^" + this.rootPath), '').replace(/\/$/, ""),
                    // playerMeta: window.nexgen.os,
                    // ngModuleMeta: window.nexgen.history
                },
            };
            let upsert_results = await window.nexgen.gqlrun(processUserEventGeneric, {
                variables: mutationVariables,
                // fetchPolicy: 'network-only'
            });
            console.log("upsert_results", upsert_results)
        // }

        if (this.inLegacyApp()) {
            // If modules is supposed to be opened in a new tab or new window, get url and module info to open in a lightbox instead
            if ((options.external === true && options.newTab === true) || options.external === true || options.newTab === true) {

                const fd = window.nexgen.getFDBridge();

                let lightboxOptions:any = {
                    height: '100%',
                    width: '100%'
                };

                // Get module info from current location and build lightboxOptions object
                let incomingURL = `${this.getCurrentLocation().origin}${this.navPath}/${navTo}`,
                    urlArray = incomingURL.split('player/').pop().split('/'),
                    hasContext = !(urlArray[0].includes('ngr-')),
                    nextModule = (hasContext) ? urlArray[1] : urlArray[0],
                    moduleID = hasContext ? urlArray[2] : urlArray[1];
                
                lightboxOptions.module = nextModule;
                lightboxOptions.params = {};

                if (nextModule === 'ngr-external-item') {
                    // Determine if this external item should open a legacy reporting page or something from the OU S3 bucket
                    let legacyExternalItem = urlArray.includes('reporting') ? true : false,
                        customHTML = urlArray.findIndex(el => el.includes(".html")),
                        filename = (customHTML > 0) ? urlArray[customHTML] : '';;

                    if (hasContext) {
                        moduleID = legacyExternalItem ? urlArray.slice(3, urlArray.length).join('/') : moduleID;
                    } else {
                        moduleID = legacyExternalItem ? urlArray.slice(2, urlArray.length).join('/') : moduleID;
                    }

                    if (legacyExternalItem) { 
                        lightboxOptions.params.routePath = '/' + moduleID;
                        lightboxOptions.id = 'reporting';
                    } else {
                        lightboxOptions.params.routePath = moduleID;
                        if (filename !== '') lightboxOptions.params.filename = filename;
                        lightboxOptions.id = moduleID;
                    }
                } else {
                    lightboxOptions.params.routePath = moduleID;
                    lightboxOptions.id = moduleID;
                };

                let meetings_arr = ['web.zoom', 'teams.microsoft', 'webex']

                if (meetings_arr.some(meeting => navTo.includes(meeting)) || navTo.match(/^http(s)?/)) {
                    fd.systemHandleLink(navTo)
                } else {
                    this.openLightbox(lightboxOptions)
                }
            }
        }

        if (options.external) {
            if (options.newTab) {
                window.open(url, '_blank');
            } else {
                if (window.nexgen.inLegacyApp()) {
                    return
                } else {
                    window.location.href = url;
                }
            }
            return;
        }

        if(url === 'back') {
            history.goBack();
            return;
        }

        if(url === 'forward') {
            history.goForward();
            return;
        }

        if (url.substr(0, 1) !== '/') url = '/' + url;


        // reset paths in modules if doing a full navigate
        if(!('navigateModule' in options)) this.modulePaths = {};

        // this fixes an issue when navigating immediately after a load
        setTimeout(() => {
            let historyMethod = history.push;
            if(options?.updatePath) {
                historyMethod = history.replace;
            }
            // if (url === '/home' || url === '/') {
            //     console.log("LOGOUT")
            //     console.log(url);
            //     if(newTab) {
            //         url === '/home' ? window.open(`${this.navPath}/`, '_blank') : window.open(`/`, '_blank');
            //     } else {
            //         url === '/home' ? historyMethod(`${this.navPath}/`, options.state) : historyMethod(`/`, options.state);
            //     };
            // } else {
                // if (!url.match(`^${this.navPath}/`)) url = `${this.navPath}${url}`;
            if(newTab) {
                window.open(`${this.navPath}/${navTo}`);
            } else {
                // if url starts with `/player` use the url as an absolute path
                if(url.match("^" + this.rootPath)) {
                    historyMethod(`${url}`, options.state);
                } else {
                    historyMethod(`${this.navPath}/${navTo}`, options.state);
                }
            };
            // };

            if('layoutState' in options) {
                if(this.getCurrentLayoutState() !== options.layoutState) {
                    this.changeLayoutState(options.layoutState);
                }
            } else {
                if(this.getCurrentLayoutState() !== null) {
                    this.changeLayoutState(null);
                }
            }
        }, 0);

        },
    

    buildNavigatePath: function() {
        let hash = this.buildNavigationHash();
        let path = this.getCurrentLocation().pathname.replace(/\/$/,'');
        return `${path}/${hash}`;
    },

    buildNavigationHash: function() {
        return '#' + Object.entries(this.modulePaths).map(([key, value]: any) => `/${key}/${value}/`).join('&');
    },

    navigateModule: function(url: string, navOptions: NavigateModuleOptions = {}) {
        navOptions = {...navOptions, navigateModule: true};
        let mid = ('id' in navOptions) ? navOptions.id : this.getProxyModuleID();
        let path = url.replace(/^\//, '').replace(/\/$/, '');
        if(path === "") {
            if(mid in this.modulePaths) delete this.modulePaths[mid]
        } else {
            this.modulePaths[mid] = path;
        }
        this.navigate(this.buildNavigatePath(), navOptions);
    },

    getModuleHash: function(mid?: string) {
        if(!mid) mid = this.getProxyModuleID();
        let hashes = this.getLocationHash().replace(/^(#)/,'').split('&').map((h: string) => h.replace(/^\//,'').replace(/\/$/,''));
        let mhash = hashes.find((h:string) => h.match(`^${mid}`));
        return mhash;
    },

    getModuleHashPath: function(mid?: string) {
        if(!mid) mid = this.getProxyModuleID();
        let hash = this.getModuleHash(mid);
        return hash ? hash.replace(new RegExp('^' + mid + '/?'), '') : undefined;
    },

    removeModuleHash: function(mid: string) {
        // called on proxy iframe unload (proxy.ts)
        if(!mid) mid = this.getProxyModuleID();

        if(mid) {
            if(mid in this.modulePaths) {
                delete this.modulePaths[mid];
                this.navigate(this.buildNavigatePath(), {updatePath: true});
            }
        }
    },

    getRouteMatch: function(route: string = `${this.rootPath}/:module?/:id?`, options: object = {}) {
        let path = window.location.pathname;
        return matchPath(path, { path: route, ...options });
    },

    matchPath: function(url: string = window.location.pathname, options: object = { path: `${this.rootPath}/:module?/:id?` }) {
        if (!url) url = window.location.pathname;
        return matchPath(url, options);
    },

    getCurrentLocation: function() {
        return window.location;
    },

    getLocationHash() {
        return this.getCurrentLocation().hash;
    },

    URLSearchParams(searchString?: string) {
        let location = searchString ? searchString : this.getCurrentLocation().search;
        return new URLSearchParams(location);
    },

    getQueryParams: function(searchString?: string) {
        let urlParams = this.URLSearchParams(searchString);
        let obj: { [key: string]: string } = {};
        urlParams.forEach((value: string, key: string) => {
            obj[key] = value;
        })
        return obj;
    },

    getQueryParam: function(param: string, searchString?: string) {
        return this.URLSearchParams(searchString).get(param);
    },

    removeQueryParam: function(param: string, options: {searchString?: string, returnSearchString?: boolean, returnUrl?: boolean} = {}) {
        let searchString = 'searchString' in options ? options.searchString : undefined;
        let loc = this.getCurrentLocation().pathname;
        let searchParams = this.getQueryParams(searchString);
        if(param in searchParams) delete searchParams[param];
        if(Object.keys(searchParams).length) {
            searchParams = "?" + (new URLSearchParams(searchParams)).toString();
        } else {
            searchParams = ''
        }

        if(options.returnSearchString === true) {
            return searchParams;
        }
        if(options.returnUrl === true) {
            return loc + searchParams;
        }

        this.navigate(loc + searchParams);
    },

    getStringKeyFromObject: function(key: string, object: Object) {
        let parts = key.split('.');
        let value: { [key: string]: any } = object;
        for (let key in parts) {
            if (value && parts[key] in value) {
                value = value[parts[key]];
            } else {
                value = null;
                break;
            }
        }
        return value
    },

    updateUrl: function(url: string, type: string = 'public') {
        console.warn('This method is deprecated');
        return this.url + url.replace(new RegExp("(.*)/" + type), '/' + type);
    },

    getModuleUrl: async function(mod: string) {
        let result = `/protected/modules/${mod}/index.html`;
        return result;
    }
}

export default PathMethods;