import CustomPromise from './promise';

class ReadyNowProxy {

    private pendingExecutionPromises: { [key: string]: { resolve: Function, reject: Function } };
    private eventSubscribers: { [eventName: string]: Array<Function> };


    constructor() {

        this.pendingExecutionPromises = {};  // callbackGUID: {resolve, reject}
        this.eventSubscribers = {}; // eventName: Array<Function>

        this.initializeListener()
    }


    private initializeListener() {
        // listen for intra-iframe messages (responses) from the parent

        window.addEventListener('message', (e) => {
            this.handleIncomingMessage(e)
        });

    }

    private handleIncomingMessage( e: MessageEvent ) {

        if( !e.data?.__uid ){
            // this.logError(`Received a message with no uid. Ignoring.`)
            return
        }

        if( !e.data?.__type ){
            // this.logError(`Received a message with no type. Ignoring.`)
            return
        }

        const uid = e.data.__uid;
        const messageType = e.data.__type;

        if( messageType === 'METHOD_RESPONSE' ) {

            if( e.data.error ){
                this.pendingExecutionPromises[uid]?.reject(e.data.error)
            } else {
                this.pendingExecutionPromises[uid]?.resolve(e.data.response ?? {})
            }
            
            delete this.pendingExecutionPromises[uid]

        } else if( messageType === 'EVENT' ) {

            if( !e.data.eventName ) {
                // this.logError(`Event execution with no name. Ignoring.`)
                return
            }

            if( !e.data.payload ) {
                // this.logError(`Event execution with no name. Ignoring.`)
                return
            }

            const eventName = e.data.eventName
            const payload = e.data.payload

            for( var i in this.eventSubscribers[eventName] ?? [] ){
                this.eventSubscribers[eventName][i]( payload );
            }

        } else {

            this.logError(`Invalid messageType "${messageType}"`)

        }

    }


    public async execute(args: { method: string, executionArguments: any }): Promise<any> {

        const { method, executionArguments } = args;

        let message: any = {};

        message.__method = method;
        message.__uid = this.uuidv4(); // assign a unique id to this execution
        message.executionArguments = executionArguments

        return new Promise((resolve, reject) => {

            this.pendingExecutionPromises[message.__uid] = { resolve, reject }

            this.postMessage(message);

        });

    }


    public on(event: string, callback: Function) {

        let message: any = {};

        message.__event = event;
        message.__uid = this.uuidv4(); // assign a unique id to this subscription

        this.eventSubscribers[event] = this.eventSubscribers[event] ?? []

        this.eventSubscribers[event].push(callback)

        this.postMessage(message);

    }


    private postMessage(message: any) {

        window.parent.parent.postMessage(message, "*");

    }


    private logError( msg: any ){
        console.error( `[ReadyNowProxy error]:`, msg )
    }


    private log( msg: any ){
        console.log( `[ReadyNowProxy]:`, msg )
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

}












export default class FDBridge {

    private ngready: any = null;
    public parent: any = null;
    public readyNowProxy: ReadyNowProxy

    constructor(ngready: any = null) {

        this.ngready = ngready;

        this.readyNowProxy = new ReadyNowProxy()

    }

    public generateID = () => {
        return Math.random().toString(36).substr(2, 9)
    };

    private getCurrentUserObject: Function = async () => {
        let userProfile;
        let username = '';
        if (!('NGUsr' in window.nexgen.AppConfig) || window.nexgen.AppConfig.NGUsr === true) {
            userProfile = await window.nexgen.getCurrentUser('fdl');
            username = userProfile.attFDLUser.username;
        } else {
            userProfile = await window.nexgen.getCurrentNGRProfile(['fdrURI', 'cognitoUsername']);
            username = userProfile.fdiURI || userProfile.cognitoUsername;
        }
        return { profile: userProfile, username };
    }


    public readynow: Function = async (method: string, args: any = {}) => {
        this.readyNowProxy.execute({
            method,
            executionArguments: args
        })
    };



    public navigationGoToPath: Function = async (url: string) => {
        if (window.nexgen.inLegacyApp()) {
            this.readynow("navigation.goToPath", { path: url })
        } else {
            if (url === '..') {
                return await this.navigationExitItem();
            } else if ('poc-generic-navigation' in window.nexgen.AppConfig.moduleIndex) {
                return await window.nexgen.navigate(`/poc-generic-navigation/all/#/poc-generic-navigation.all/` + url);
            } else {
                return await window.nexgen.navigate(url);
            }
        }
    };




    public navigationExitItem: Function = async () => {
        if (window.nexgen.inLegacyApp()) {
            this.readynow("navigation.exitItem");
        } else {
            if (window.nexgen.parent.state.frames.length > 0) {
                return await window.nexgen.closeAllLightboxes();
            } else {
                return await window.nexgen.navigate('back');
            }
        }
    };

    public systemHandleLink: Function = async (url: string) => {
        if (window.nexgen.inLegacyApp()) {
            this.readynow("system.handleUrl", { url });
        } else {
            return await window.nexgen.navigate(url, true, true);
        }
    };


    public dataGetStoreAsDictonary: Function = () => {
        let promise: any = new CustomPromise();
        (async () => {
            let config = await window.nexgen.getConfig(this.ngready);
            promise.resolve(config);
        })();
        return promise;
    };
    public dataGetValueAsString: Function = (key: string) => {
        let promise: any = new CustomPromise();
        (async () => {
            if (key === 'user') {
                if (!('NGUsr' in window.nexgen.AppConfig) || window.nexgen.AppConfig.NGUsr === true) {
                    let user = await window.nexgen.getCurrentUser('fdl');
                    promise.resolve(user ? user.attFDLUser.username : null);
                } else {
                    let user = await window.nexgen.getCurrentNGRProfile(['fdrURI']);
                    promise.resolve(user ? user.fdrURI : null);
                }
            } else {
                let config = await window.nexgen.getConfig(this.ngready);
                promise.resolve(key in config ? config[key] : null);
            }
        })();
        return promise;
    };

    public metaGetApiVersion: Function = async () => {
        return "1.0.0";
    };
    public systemGetAppVersion: Function = async () => {
        return "1.0.0";
    };
    public keyedValuesGetByScope: Function = (scope: string) => {
        let promise: any = new CustomPromise();
        (async () => {
            let result = await fetch("/legacy-realtime/rts-api/KeyedValues/find", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    keyScope: scope
                })
            })
            promise.resolve(result);
        })();
        return promise;

    };
    public keyedValueGet: Function = (key: string) => {
        let promise: any = new CustomPromise();
        (async () => {
            let result = await fetch("/legacy-realtime/rts-api/KeyedValues/find", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    key
                })
            })
            promise.resolve(result);
        })();
        return promise;
    };
    public keyedValueSet: Function = (key: string, data: string) => {
        let promise: any = new CustomPromise();
        (async () => {
            try {
                let partition = window.nexgen.getPartition();
                let result = await fetch("/legacy-realtime/rts-api/KeyedValues/upsert", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        key,
                        partition,
                        value: data
                    })
                })
                promise.resolve(result);
            } catch (e) {
                console.log(e);
            }
        })();
        return promise;
    };

    public messageGetMediaBaseUrl: Function = () => { };
    public appGetPartitionName: Function = () => {
        let promise: any = new CustomPromise();
        (async () => {
            promise.resolve(window.nexgen.getPartition());
        })();
        return promise
    };


    public userProfileGetCurrent: Function = () => {
        let promise: any = new CustomPromise();
        (async () => {
            let obj = await this.getCurrentUserObject();
            let profile = 'attFDLUser' in obj.profile ? {
                ...obj.profile.attFDLUser,
                app_username: obj.profile.attFDLUser.username,
                emailAddress: obj.profile.attFDLUser.email
            } : {
                ...obj.profile
            }
            promise.resolve(profile);
        })();
        return promise
    };

    public userProfileGetById: Function = () => { };
    public userProfileGetByUsername: Function = () => { };
    public pdfLoadViewWithItem: Function = (item: string) => {
        window.nexgen.openLightbox({
            module: 'ngr-pdf-engine',
            id: 'legacy-pdf',
            params: {
                url: '/' + item
            },
            styles: {
                background: {
                    background: "#000",
                    opacity: 0.5
                },
                box: {}
            },
            options: {
                closeButton: true
            }
        })
    };
    public navigationPresentProfilePopup: Function = () => {
        window.nexgen.openProfile({
            module: 'ngr-profile'
        })
    };

    public userDataSave: Function = async (data: any) => {
        let userData = await this.getCurrentUserObject();
        let partition = window.nexgen.getPartition();
        let variables: any = {
            input: {
                user: userData.username,
                navLabelPath: "",
                activity: "",
                partition,
                ...data
            }
        }

        if (!partition) {
            return null;
        }

        let result = await window.nexgen.run(`
            mutation createFDRSUserDataGeneric(
                $input: CreateFDRSUserDataGeneric!
            ){
                createFDRSUserDataGeneric(input: $input) {
                    data,
                    id,
                    partition,
                    navLabelPath
                }
            }`,
            {
                variables
            });


        return !!result;

    }

    public userDataGet: Function = async (data: any) => {
        let userData = await this.getCurrentUserObject();
        let partition = window.nexgen.getPartition();
        let variables: any = {
            user: userData.username,
            partition,
            ...data
        }

        if (!partition) {
            return null;
        }

        let result = await window.nexgen.run(`
            query listFDRSUserDataGeneric(
                $user: String
                $partition: String
                $item: String
                $navLabelPath: String
                $activity: String
                $attempt: Int
            ){
                listFDRSUserDataGeneric(user: $user, partition: $partition, item: $item, navLabelPath: $navLabelPath, activity: $activity, attempt: $attempt) {
                    data,
                    id,
                    partition,
                    navLabelPath,
                    attempt,
                    activity
                }
            }`,
            {
                variables,
                fetchPolicy: "network-only"
            });

        return result
    };


    public userDataSaveTextInput: Function = (key: string, data: { text: string, activity: string, correct: boolean, version: number, attempt: number, points: number, bonusPoints: number, penaltyPoints: number, timeSpent: number, meta: any }) => {
        let promise: any = new CustomPromise();
        if (!data.activity) {
            data.activity = "";
        }
        if (!data.correct) {
            data.correct = false;
        }
        if (!data.version) {
            data.version = 1;
        }
        if (!data.attempt) {
            data.attempt = 1;
        }
        if (!data.points) {
            data.points = 0;
        }
        if (!data.bonusPoints) {
            data.bonusPoints = 0;
        }
        if (!data.penaltyPoints) {
            data.penaltyPoints = 0;
        }
        if (!data.timeSpent) {
            data.timeSpent = 0;
        }
        if (!data.meta) {
            data.meta = "";
        }
        (async () => {
            let inputData: any = {
                item: key,
                itemType: "TEXTINPUT",
                data: data.text,
                navLabelPath: window.nexgen.getCurrentLocation().pathname,
                activity: data.activity,
                correct: data.correct,
                version: data.version,
                attempt: data.attempt,
                points: data.points,
                bonus_points: data.bonusPoints,
                penalty_points: data.penaltyPoints,
                timeSpent: data.timeSpent,
                meta: data.meta
            }
            try {
                let result = await this.userDataSave(inputData);
                promise.resolve(result);
            } catch (e) {
                console.log(e);
            }
        })();

        return promise;
    };
    public userDataGetTextInput: Function = (item_name: string, base_path?: string, activity?: string, attempt?: number) => {
        let promise: any = new CustomPromise();
        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "TEXTINPUT"
            },
                result = [];

            if (base_path) inputData.navLabelPath = base_path;
            if (attempt) inputData.attempt = attempt;
            if (activity) inputData.activity = activity;

            try {
                result = await this.userDataGet(inputData);
            } catch (e) {
                promise.reject();
                return;
            }

            if (!result || result.length === 0) {
                promise.reject()
                return;
            } else {
                promise.resolve({
                    text: result[0].data
                });
                return;
            }

        })();
        return promise;
    };
    public userDataSaveObject: Function = (item_type: string, item_name: string, item_data: any) => {
        let promise: any = new CustomPromise();
        if (!item_data) {
            // so the first argument isn't required
            item_data.data = item_name;
            item_name = item_type;
            item_type = "OBJECT";
        }
        if (!item_data.activity) {
            item_data.activity = "";
        }
        if (!item_data.correct) {
            item_data.correct = false;
        }
        if (!item_data.version) {
            item_data.version = 1;
        }
        if (!item_data.attempt) {
            item_data.attempt = 1;
        }
        if (!item_data.points) {
            item_data.points = 0;
        }
        if (!item_data.bonusPoints) {
            item_data.bonusPoints = 0;
        }
        if (!item_data.penaltyPoints) {
            item_data.penaltyPoints = 0;
        }
        if (!item_data.timeSpent) {
            item_data.timeSpent = 0;
        }
        if (!item_data.meta) {
            item_data.meta = "";
        }
        (async () => {
            // not really sure what item_type is used for, but with nexgen itemType is an enum
            let inputData: any = {
                item: item_name,
                itemType: item_type,
                data: JSON.stringify(item_data.data),
                navLabelPath: window.nexgen.getCurrentLocation().pathname,
                activity: item_data.activity,
                correct: item_data.correct,
                version: item_data.version,
                attempt: item_data.attempt,
                points: item_data.points,
                bonus_points: item_data.bonusPoints,
                penalty_points: item_data.penaltyPoints,
                timeSpent: item_data.timeSpent,
                meta: item_data.meta
            }
            try {
                let result = await this.userDataSave(inputData);
                promise.resolve(result);
            } catch (e) {
                promise.reject(false)
            }
        })();
        return promise;
    };
    public userDataGetObject: Function = (item_type: string, item_name: string | null, item_path?: string, activity?: string, attempt?: number) => {
        let promise: any = new CustomPromise();
        if (!item_name) {
            // so the first argument isn't required
            item_name = item_type;
            item_type = "OBJECT";
        }
        (async () => {
            let inputData: any;
            inputData = {
                item: item_name,
                itemType: item_type
            }
            if (item_path) inputData.navLabelPath = item_path;
            if (attempt) inputData.attempt = attempt;
            if (activity) inputData.activity = activity;

            let result = await this.userDataGet(inputData);
            if (!result || result.length === 0) {
                promise.reject(null);
            } else {
                promise.resolve(JSON.parse(result[0].data));
            }
        })();
        return promise;
    };

    public userDataSaveRanking: Function = (item_name: string, item_data: { ranks: { rank: number, answer: string }[], activity: string, correct: boolean, version: number, attempt: number, points: number, bonusPoints: number, penaltyPoints: number, timeSpent: number, meta: any }) => {
        let promise: any = new CustomPromise();

        if (!item_data.activity) {
            item_data.activity = "";
        }
        if (!item_data.correct) {
            item_data.correct = false;
        }
        if (!item_data.version) {
            item_data.version = 1;
        }
        if (!item_data.attempt) {
            item_data.attempt = 1;
        }
        if (!item_data.points) {
            item_data.points = 0;
        }
        if (!item_data.bonusPoints) {
            item_data.bonusPoints = 0;
        }
        if (!item_data.penaltyPoints) {
            item_data.penaltyPoints = 0;
        }
        if (!item_data.timeSpent) {
            item_data.timeSpent = 0;
        }
        if (!item_data.meta) {
            item_data.meta = "";
        }

        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "RANKING",
                data: item_data.ranks.map(r => r.rank).join('|'),
                navLabelPath: window.nexgen.getCurrentLocation().pathname,
                activity: item_data.activity,
                correct: item_data.correct,
                version: item_data.version,
                attempt: item_data.attempt,
                points: item_data.points,
                bonus_points: item_data.bonusPoints,
                penalty_points: item_data.penaltyPoints,
                timeSpent: item_data.timeSpent,
                meta: item_data.meta
            }
            try {
                let result = await this.userDataSave(inputData);
                promise.resolve(result);
            } catch (e) {
                promise.reject(false);
            }
        })();
        return promise;

    }

    public userDataGetRanking: Function = (item_name: string, item_path?: string, activity?: string, attempt?: number) => {
        let promise: any = new CustomPromise();
        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "RANKING"
            }
            if (item_path) inputData.navLabelPath = item_path;
            if (attempt) inputData.attempt = attempt;
            if (activity) inputData.activity = activity;

            let result = await this.userDataGet(inputData);
            if (result.length === 0) {
                promise.reject(null);
            } else {
                promise.resolve({ ranks: result[0].data.split('|').map((d: string | number) => ({ rank: d })) });
            }
        })();
        return promise;
    }

    public userDataSaveRating: Function = (item_name: string, item_data: { rating: number, activity: string, correct: boolean, version: number, attempt: number, points: number, bonusPoints: number, penaltyPoints: number, timeSpent: number, meta: any }) => {
        let promise: any = new CustomPromise();
        if (!item_data.activity) {
            item_data.activity = "";
        }
        if (!item_data.correct) {
            item_data.correct = false;
        }
        if (!item_data.version) {
            item_data.version = 1;
        }
        if (!item_data.attempt) {
            item_data.attempt = 1;
        }
        if (!item_data.points) {
            item_data.points = 0;
        }
        if (!item_data.bonusPoints) {
            item_data.bonusPoints = 0;
        }
        if (!item_data.penaltyPoints) {
            item_data.penaltyPoints = 0;
        }
        if (!item_data.timeSpent) {
            item_data.timeSpent = 0;
        }
        if (!item_data.meta) {
            item_data.meta = "";
        }
        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "RATING",
                data: item_data.rating,
                navLabelPath: window.nexgen.getCurrentLocation().pathname,
                activity: item_data.activity,
                correct: item_data.correct,
                version: item_data.version,
                attempt: item_data.attempt,
                points: item_data.points,
                bonus_points: item_data.bonusPoints,
                penalty_points: item_data.penaltyPoints,
                timeSpent: item_data.timeSpent,
                meta: item_data.meta
            }
            try {
                let result = await this.userDataSave(inputData);
                promise.resolve(result);
            } catch (e) {
                promise.reject(false);
            }
        })();
        return promise;
    }

    public userDataGetRating: Function = (item_name: string, item_path?: string, activity?: string, attempt?: number) => {
        let promise: any = new CustomPromise();
        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "RATING"
            }
            if (item_path) inputData.navLabelPath = item_path;
            if (attempt) inputData.attempt = attempt;
            if (activity) inputData.activity = activity;

            let result = await this.userDataGet(inputData);
            if (result.length === 0) {
                promise.reject(null);
            } else {
                promise.resolve({ rating: parseInt(result[0].data) });
            }
        })();
        return promise;
    }

    public userDataSaveMultipleChoice: Function = (item_name: string, item_data: { choices: { choice: number | string }[], activity: string, correct: boolean, version: number, attempt: number, points: number, bonusPoints: number, penaltyPoints: number, timeSpent: number, meta: any }) => {
        let promise: any = new CustomPromise();
        if (!item_data.activity) {
            item_data.activity = "";
        }
        if (!item_data.correct) {
            item_data.correct = false;
        }
        if (!item_data.version) {
            item_data.version = 1;
        }
        if (!item_data.attempt) {
            item_data.attempt = 1;
        }
        if (!item_data.points) {
            item_data.points = 0;
        }
        if (!item_data.bonusPoints) {
            item_data.bonusPoints = 0;
        }
        if (!item_data.penaltyPoints) {
            item_data.penaltyPoints = 0;
        }
        if (!item_data.timeSpent) {
            item_data.timeSpent = 0;
        }
        if (!item_data.meta) {
            item_data.meta = "";
        }
        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "MULTIPLECHOICE",
                data: item_data.choices.map(c => c.choice).join(','),
                navLabelPath: window.nexgen.getCurrentLocation().pathname,
                activity: item_data.activity,
                correct: item_data.correct,
                version: item_data.version,
                attempt: item_data.attempt,
                points: item_data.points,
                bonus_points: item_data.bonusPoints,
                penalty_points: item_data.penaltyPoints,
                timeSpent: item_data.timeSpent,
                meta: item_data.meta
            }
            try {
                let result = await this.userDataSave(inputData);
                promise.resolve(result);
            } catch (e) {
                promise.reject(false);
            }
        })();
        return promise;
    }

    public userDataGetMultipleChoice: Function = (item_name: string, item_path?: string, activity?: string, attempt?: number) => {
        let promise: any = new CustomPromise();
        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "MULTIPLECHOICE"
            }
            if (item_path) inputData.navLabelPath = item_path;
            if (attempt) inputData.attempt = attempt;
            if (activity) inputData.activity = activity;

            let result = await this.userDataGet(inputData);
            if (result.length === 0) {
                promise.reject(null);
            } else {
                promise.resolve({ choices: result[0].data.split(',').map((d: string | number) => ({ choice: d })) });
            }
        })();
        return promise;
    }

    public userDataSaveNumeric: Function = (item_name: string, item_data: { number: number, activity: string, correct: boolean, version: number, attempt: number, points: number, bonusPoints: number, penaltyPoints: number, timeSpent: number, meta: any }) => {
        let promise: any = new CustomPromise();
        if (!item_data.activity) {
            item_data.activity = "";
        }
        if (!item_data.correct) {
            item_data.correct = false;
        }
        if (!item_data.version) {
            item_data.version = 1;
        }
        if (!item_data.attempt) {
            item_data.attempt = 1;
        }
        if (!item_data.points) {
            item_data.points = 0;
        }
        if (!item_data.bonusPoints) {
            item_data.bonusPoints = 0;
        }
        if (!item_data.penaltyPoints) {
            item_data.penaltyPoints = 0;
        }
        if (!item_data.timeSpent) {
            item_data.timeSpent = 0;
        }
        if (!item_data.meta) {
            item_data.meta = "";
        }
        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "NUMERIC",
                data: item_data.number,
                navLabelPath: window.nexgen.getCurrentLocation().pathname,
                activity: item_data.activity,
                correct: item_data.correct,
                version: item_data.version,
                attempt: item_data.attempt,
                points: item_data.points,
                bonus_points: item_data.bonusPoints,
                penalty_points: item_data.penaltyPoints,
                timeSpent: item_data.timeSpent,
                meta: item_data.meta
            }
            try {
                let result = await this.userDataSave(inputData);
                promise.resolve(result);
            } catch (e) {
                promise.reject(false);
            }
        })();
        return promise;
    }

    public userDataGetNumeric: Function = (item_name: string, item_path?: string, activity?: string, attempt?: number) => {
        let promise: any = new CustomPromise();
        (async () => {
            let inputData: any = {
                item: item_name,
                itemType: "NUMERIC"
            }
            if (item_path) inputData.navLabelPath = item_path;
            if (attempt) inputData.attempt = attempt;
            if (activity) inputData.activity = activity;

            let result = await this.userDataGet(inputData);
            if (result.length === 0) {
                promise.reject(null);
            } else {
                promise.resolve({ number: parseInt(result[0].data) });
            }
        })();
        return promise;
    }

    public itemDataGetSelf: Function = () => {
        let promise: any = new CustomPromise();
        (async () => {
            promise.resolve(null);
        })();
        return promise;
    }

}