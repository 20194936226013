
export class SessionControlHelper {

    private isUpsertingSession: boolean = false

    public get isEnabled(): boolean {
        const isSessionLimitConfigured = Number(window.nexgen.AppConfig.sessionLimit ?? 0) > 0
        const hasGQLClient = !!window.nexgen.gqlClient

        return hasGQLClient && isSessionLimitConfigured
    }

    // Generates and persists a unique id for this device.
    public upsertRecordedDeviceKey( withValue: string = "" ): string {
        
        const KEY = `ngDevUSID`

        let deviceKey = localStorage.getItem(KEY);

        if( !deviceKey ) { 
            // random string
            deviceKey = withValue.length > 0 ? withValue : (Math.random() + 1).toString(36).substring(2);
            localStorage.setItem(KEY, deviceKey);
        }

        return deviceKey

    }


    public get deviceUserSessionToken(): string {
        return this.upsertRecordedDeviceKey()
    }

    public async getActiveUserSessions(): Promise<any[]> { // returns array of UserSession. todo: add types.

        if( !this.isEnabled ){ return [] }

        let activeSessions = await window.nexgen.gqlrun(`
            query getActiveUserSessions {
                getActiveUserSessions {
                    id,
                    sessionToken,
                    isActive,
                    createdAt,
                    updatedAt,
                    userId
                }
            }`, {fetchPolicy: "network-only"}
        );

        return activeSessions ?? [];

    }

    public async getCurrentUserSession(): Promise<any | undefined> { // returns the UserSession or undefined. todo: add types.

        if( !this.isEnabled ){ return }

        const token = this.deviceUserSessionToken
        const activeSessions = await this.getActiveUserSessions()

        return activeSessions.find(s => s.sessionToken === token)

    }

    public async upsertUserSession(): Promise<any> { // returns the UserSession. todo: add types.

        try {

            if( !this ){ return } // odd safari behavior

            if( !this.isEnabled ){ return }

            if( this.isUpsertingSession ) { return }

            this.isUpsertingSession = true

            const expirySeconds = Number(window.nexgen.AppConfig.sessionLimitExpirySeconds ?? 10800);

            const token = this.deviceUserSessionToken
            
            const newSession = await window.nexgen.gqlrun(`
                mutation registerUserSession($input:registerUserSessionInput!) {
                    registerUserSession(input: $input){
                        success
                        message
                        userSession{
                            id,
                            sessionToken,
                            createdAt,
                            updatedAt,
                            userId
                        }
                    }
                }`,
                {
                    fetchPolicy: "network-only",
                    variables: {
                        input: {
                            sessionToken: token,
                            expiresAt: new Date(Date.now() + (expirySeconds * 1000)).toISOString()
                        }
                    }
                }
            );

            setTimeout(() => {
                this.isUpsertingSession = false;
            },300)
        
            return newSession.userSession;
        } catch (e: any) {

            console.error(e)

        } 
    }

    public async endCurrentUserSession(): Promise<void> { 
        
        if( !this.isEnabled ){ return }

        const token = this.deviceUserSessionToken
        
        let result = await window.nexgen.gqlrun(`
            mutation endUserSessionByToken($input: endUserSessionByTokenInput!) {
                endUserSessionByToken(input: $input) {
                    success
                    message
                } 
            }`,
            {
                fetchPolicy: "network-only",
                variables: {
                    input: {
                        sessionToken: token
                    }
                }
            }
        );

    }

    public async endAllActiveUserSessions(): Promise<void> { 
        
        if( !this.isEnabled ){ return }

        let response = await window.nexgen.gqlrun(`
            mutation endAllActiveUserSessions {
                endAllActiveUserSessions{
                    success
                    message
                } 
            }`, {fetchPolicy: "network-only"}
        );

        //TODO: Ask cognito to end all other sessions

    }

    private observerInterval: ReturnType<typeof setInterval> | undefined
    private observerQueryResult: string = ''


    public async observeSessionChanges( callback: Function ): Promise<any> {

        if( !this.isEnabled ){ return }

        if( this.observerInterval !== undefined ){
            return
        }

        this.observerInterval = setInterval(async () => {

            let currentSession = await this.getCurrentUserSession()
            let encodedResult: string = JSON.stringify(currentSession ?? '')

            // if the session hasn't changed, do nothing
            if( this.observerQueryResult === encodedResult ){ return }

            this.observerQueryResult = encodedResult

            callback(currentSession)

        }, 5000);


        // a subscription based implementation would be ideal:

        // await window.nexgen.gqlrun(`
        //     query getActiveUserSessions {
        //         getActiveUserSessions {
        //             id,
        //             sessionToken,
        //             isActive,
        //             userId
        //         }
        //     }`,
        //     {
        //         subscription: {
        //             query: `
        //                 getActiveUserSessions {
        //                     id,
        //                     sessionToken,
        //                     isActive,
        //                     userId
        //                 }
        //             `,
        //             callback: (data: any) => {
        //                 callback(data)
        //             }
        //         }
        //     }
        // )
    }

};

const sessionControlHelper = new SessionControlHelper();

export { sessionControlHelper }