import NexgenInstance from '../utils/NexgenInstance';
import { v4 } from 'uuid';
import { ProxyModuleData } from '../types/api.types';




const ProxyMethods = {
    getProxyInstanceID: function(): string {
        return null;
    },
    getProxyWindow: function(): HTMLFrameElement {
        return null;
    },
    getProxyModuleData: function(): ProxyModuleData {
        return {};
    },
    getProxyModuleID: function(): string {
        return null;
    },

    instanceCreator: function(frame: HTMLFrameElement | Window, instanceID?: string): NexgenInstance {
        return new NexgenInstance(frame, instanceID);
    },

    createProxy: function(frame: HTMLFrameElement | Window, nexgen: any) {
        let instanceID = v4();
        var results = (/function (.{1,})\(/).exec(frame.constructor.toString());
        if(results && results[1] === 'HTMLIFrameElement') {
            (frame as HTMLFrameElement).setAttribute("data-playerid", instanceID);
        }
        
        let ngInstance: any = this.instanceCreator(frame, instanceID);

        let fw = 'contentWindow' in frame ? frame.contentWindow : frame;

        if(fw.moduleInfo) {
            // (async() => {
            //     let moduleInfo = null;
            //     let url = './meta.json';
            //     if('src' in frame) {
            //         moduleInfo = fw.moduleInfo;
            //         url = frame.src.replace('index.html','meta.json')
            //     } else if('moduleInfo' in frame) {
            //         moduleInfo = frame.moduleInfo;
            //     }

            //     try {
            //         let meta = await this.getAsset(url, {
            //             headers: {
            //                 'pragma': 'no-cache',
            //                 'cache-control': 'no-cache'
            //             }
            //         });
            //         if(meta && moduleInfo) this.compareVersions(meta, moduleInfo);
            //     } catch(e) {}

            // })();
        }

        fw.nexgenInstance = ngInstance;
        fw.onunload = () => {
            //@ts-ignore
            fw.nexgen.removeModuleHash();
            if(instanceID) {
                if(instanceID in this.signalControllers) {
                    for(let controllerID in this.signalControllers[instanceID]) {
                        this.signalControllers[instanceID][controllerID].abort();
                    }
                }
                this.removeSubscriptions(instanceID);
                fw.nexgen.top.rootNexgen.unload(instanceID);
            }
        }

        let nexgenProxy = new Proxy(nexgen.top.rootNexgen, {
            get: function(target: any, prop, receiver) {
                if(prop in ngInstance) {
                    return function(...args: any) {
                        return ngInstance[prop].apply(ngInstance, [...args])
                    }
                } else if(prop in target) {
                    return target[prop];
                }
            }
        });

        // nexgenProxy.on('theme', (theme: any) => {
        //     nexgenProxy.applyTheme(theme, true);
        // })

        // // should apply the default theme but can override in module
        // nexgenProxy.applyTheme(this.twCurrentTheme, true);

        return nexgenProxy;

    }
}

export default ProxyMethods;