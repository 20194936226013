import { v4 } from 'uuid';
import {
    getPartitionType
} from '../utils/functions';

var currentLayoutState: string | null = null;

const CommonMethods = {

    getVersionInfo: function(module?: string) {
        let proxyModule = this.getProxyModuleData();
        let moduleName = module ? module : proxyModule.name;
        if(!('versions' in this.AppConfig)) {
            console.error('Versions are not defined in the app config');
            return null;
        }

        if(moduleName in this.AppConfig.versions) {
            return this.AppConfig.versions[moduleName];
        } else {
            console.error(`${moduleName} not found in version list`);
            return null;
        }
    },

    getModuleHash: function(module?: string) {
        let versionInfo = this.getVersionInfo(module);
        if(typeof versionInfo === 'object' && versionInfo) {
            if('hash' in versionInfo) {
                return versionInfo.hash;
            } else {
                console.error('Module hash not found');
                return null;
            }
        } else {
            return versionInfo;
        }
    },
    togglePreloader: function(show: boolean) {
        let el = document!.querySelector<HTMLInputElement>(".loader-container");
        if (el) {
         if(show) {
            el.removeAttribute("style");
        } else {
            el.setAttribute("style","display:none !important");

        }
        }
    },
    getBrowserInfo: function() {
        return {
            browser: this.browser,
            os: this.os,
            header: this.userAgent,
            userAgent: navigator.userAgent
        }
    },

    getVersionNumber: function(module?: string) {
        let versionInfo = this.getVersionInfo(module);
        if(typeof versionInfo === 'object' && versionInfo) {
            if('version' in versionInfo) {
                return versionInfo.version;
            } else {
                console.error('Module version not found');
                return null;
            }
        } else {
            return versionInfo;
        }
    },
    
    
    matchFilter: function(filter: any, profile: any) {
        let match: boolean[] = [];

        if (!profile || (profile !== null && typeof profile === 'object' && Object.keys(profile).length === 0)) return false;

        if(Array.isArray(filter)) {

            filter.forEach((filter: any) => {

                let flattened = this.flatten(profile);

                if(!(filter.key in flattened)) return false;
                let profileValue = flattened[filter.key];

                switch (filter.type) {

                    case "date":

                        break;

                    case "profile":
                    default:

                        switch (filter.operator) {
                            // eq, ne, gt, gte, in, lt, lte, nin
                            case "eq":
                                // equal
                                match.push(profileValue === filter.value)
                                break;

                            case "ne":
                                // not equal
                                match.push(profileValue !== filter.value);
                                break;

                            case "gt":
                                // greater than
                                match.push(profileValue > filter.value)
                                break;

                            case "gte":
                                // greater than or equal
                                match.push(profileValue >= filter.value)
                                break;

                            case "lt":
                                // less than
                                match.push(profileValue < filter.value)
                                break;

                            case "lte":
                                // less than or equal
                                match.push(profileValue <= filter.value)
                                break;

                            case "has":
                            case "in":
                                // in array
                                if (typeof filter.value === 'string') {
                                    if (Array.isArray(profileValue)) {
                                        // match a string in an array
                                        match.push(profileValue.includes(filter.value));
                                    }
                                } else if (Array.isArray(filter.value)) {
                                    if (Array.isArray(profileValue)) {
                                        // match an array of strings in an array
                                        let inarr = true;
                                        filter.value.forEach((f: string) => {
                                            if (!profileValue.includes(f)) {
                                                inarr = false;
                                            }
                                        })

                                        match.push(inarr);
                                    }
                                }
                                break;

                            case "nhas":
                            case "nin":
                                // not in array
                                if (typeof filter.value === 'string') {
                                    if (Array.isArray(profileValue)) {
                                        // match a string in an array
                                        match.push(!profileValue.includes(filter.value))
                                    } else {
                                        match.push(filter.value !== profileValue)
                                    }
                                } else if (Array.isArray(filter.value)) {
                                    if (Array.isArray(profileValue)) {
                                        // match an array of strings in an array
                                        let inarr = false;
                                        filter.value.forEach((f: string) => {
                                            if (!profileValue.includes(f)) {
                                                inarr = true;
                                            }
                                        })

                                        match.push(inarr);
                                    }
                                }
                                break;
                        }
                }

            });

            return !match.includes(false);

        } else if(typeof filter === 'object') {
            
        } else {
            console.error('Unsupported filter type: ', typeof filter);
        }
    },

    flattenObject: function (obj: { [key: string]: any }, prefix: string = '') {
        var global: any = this;
        Object.keys(obj).reduce((acc: any, k: string) => {
            const pre = prefix.length ? prefix + '.' : '';
            if (typeof obj[k] === 'object' && obj[k] !== null) Object.assign(acc, global.flattenObject(obj[k], pre + k));
            else acc[pre + k] = obj[k];
            return acc;
        }, {})
    },

    flatten: function(obj: any, keepArrays: boolean = true) {
        let res: any = {};
        (function recurse(obj, current?: any) {
            for (var key in obj) {
                var value = obj[key];
                var newKey: any = (current ? current + "." + key : key);  // joined key with dot
                if (value && typeof value === "object") {
                    if(keepArrays && Array.isArray(value)) res[newKey] = value
                    else recurse(value, newKey);  // it's a nested object, so do it again
                } else {
                    res[newKey] = value;  // it's not an object, so set the property
                }
            }
        })(obj)
        return res;
    },

    expand: function(obj: any) {
        const set = (o: any = {}, a: any, value: any) => {
            const k = a.shift();
            o[k] = a.length ? set(o[k], a, value) : value;
            return o;
        }

        return Object.entries(obj).reduce((o, a) => set(o, a[0].split('.'), a[1]), {});
    },


    buildQuery: function(...args: any[]) {
        const set = (o: any = {}, a: any) => {
            const k = a.shift();
            o[k] = a.length ? set(o[k], a) : null;
            return o;
        }

        const o = args.reduce((o, a) => set(o, a.split('.')), {});

        return JSON.stringify(o)
            .replace(/"|:|null/g, '')
            .replace(/^\{/, '')
            .replace(/\}$/, '');
    },

    getUUID: function() {
        return v4();
    },

    setConfig: function(obj: {[key: string]: any}) {
        this.config = obj;
    },

    buildContextKey: function(name?: string, id?: string) {
        let value = '';
        if (name) {
            value = name;
            if (id) value += '-' + id;
            else value += '-' + name;
        } else {
            let route: any = this.matchPath(window.location.pathname, { path: `${this.rootPath}/:name/:id?` });
            if (route) {
                value = route.params.name;
                if (route.params.id) value += '-' + route.params.id;
                else value += '-' + route.params.name;
            }
        }
        return value;
    },

    currentModuleValues: function() {
        let value: {name?: string, id?: string} = {};
        let route: any = this.matchPath(window.location.pathname, { path: `${this.rootPath}/:name?/:id?` });

        // let layouts = 'layouts' in this.parent.state.config ? this.parent.state.config.layouts : null;
        // let key = this.parent.getLayoutKey(window);
        // let map = this.parent.getLayoutMap(key);

        if (route) {
            if(!route.params.name) {

            } else {
                value.name = route.params.name;
                if (route.params.id) value.id = route.params.id;
            }
        }
        return value;
    },

    getFrameProps: function(key: string) {
        return key in this.parent.frameProps ? this.parent.frameProps[key] : null;
    },

    getFrame: function(src: any) {
        let frames: any[] = Array.prototype.slice.call(document.getElementsByTagName('iframe'), 0);
        let frame: any = null;

        if (typeof src === 'string') {
            frame = frames.find(f => f.id === src);
        } else {
            frame = frames.find(f => f.contentWindow === src);
        }
        return frame;
    },

    getPartitionType: function(ngr: boolean = false) {
        let location = this.getCurrentLocation();
        return getPartitionType(location, ngr);
    },

    getPartition: function() {
        if(!('partition' in this.AppConfig)) {
            console.error('Partition value not set in app config');
            return null;
        }
        return this.AppConfig.partition;
    },

    getSection: function() {
        let partition = this.getPartition();
        if(partition) {
            return partition.replace(/_(sandbox|test|release|production|staging)$/, '');
        }
        return null;
    },

    replaceObjectValues: function(o: { [key: string]: any } | string, object: Object) {

        let isString = false;
        if(typeof o === 'string') {
            isString = true;
            o = {key: o}
        }
        for (let x in o) {
            if (typeof o[x] === 'string') {
                let match = o[x].match(/{{([^}]*)}}/g);
                if (match) {
                    for(var m in match) {
                        o[x] = o[x].replace(match[m], this.getStringKeyFromObject(match[m].replace(/[{}]/g, ''), object));
                    }
                }
            }
        }
        return isString ? o.key : o;
    },

    toggleHeader: async function(show: boolean) {
        this.parent.setState({ hideHeader: !show })
    },
    
    isLoggedIn: function() {
        return this.parent.props.loggedIn;
    },

    changeLayout: function(layout: string) {
        this.callEvent('layout_change', layout)
    },

    changeLayoutState: function(layoutState: string) {
        currentLayoutState = layoutState;
        this.callEvent('layout_state_change', layoutState)
    },

    getCurrentLayoutState: function() {
        return currentLayoutState;
    },

    setBlockContent: function(block: string, data: {name: string, id: string, params?: Object}) {
        this.callEvent('block_change', {block, data});
    },

    clearCache: function() {
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function(names) {
            for (let name of names) caches.delete(name);
          });
        }
    },
    refreshCacheAndReload: function() {
        this.clearCache();
        window.location.reload(true);
    },

    compareVersions: function(v1: string | {version: string}, v2: string | {version: string}, clearCache: boolean = true, reload: boolean = false) {

        if(typeof v1 === 'object') v1 = v1.version;
        if(typeof v2 === 'object') v2 = v2.version;

        const versionsA = v1.split(/\./g);
        const versionsB = v2.split(/\./g);
        let update = false;


        while (versionsA.length || versionsB.length) {
            const a = Number(versionsA.shift());

            const b = Number(versionsB.shift());
            // eslint-disable-next-line no-continue
            // if higher version (ex major version number) has already set update, continue since update is required
            if (a === b || update) continue;
            // eslint-disable-next-line no-restricted-globals
            update = a > b || isNaN(b);
        }

        if(clearCache && update) {
            if(reload) {
                this.refreshCacheAndReload();
            } else {
                this.clearCache();
                console.log('Cache has been cleared. Please reload to update content.')
            }
        }
        return update;
    }
}

export default CommonMethods;