import { EventList, LightboxOptions } from '../types/api.types';
import { Auth } from 'aws-amplify';

const NGRProfileMethods = {

    fullNGRProfileQuery: `
        id
        ngrState
        cognitoUserID
        cognitoUsername
        fdrPartition
        fdrURI
        themePreference
        email
        phoneNumber
        firstName
        lastName
        title
        profileImage
        fdrFlags
    `,

    profileExists: async function(user: any) {
        let profile = false,
            checkFlow: any = [
                {
                    query: "ngrProfileByCognitoID",
                    key: "cognitoUserID",
                    type: "ID",
                    value: "signInUserSession.idToken.payload.sub"
                },
                {
                    query: "ngrProfileByCognitoUsername",
                    key: "cognitoUsername",
                    type: "String",
                    value: "username"
                },
                {
                    query: "ngrProfileByCognitoUsername",
                    key: "cognitoUsername",
                    type: "String",
                    value: "username",
                    lowercase: true
                },
                {
                    query: "ngrProfileByEmail",
                    key: "email",
                    type: "String",
                    value: "signInUserSession.idToken.payload.email",
                    checkResponse: {
                        key: "cognitoUserID",
                        value: "undefined"
                    }
                },
                {
                    query: "ngrProfileByEmail",
                    key: "email",
                    type: "String",
                    value: "signInUserSession.idToken.payload.email",
                    lowercase: true,
                    checkResponse: {
                        key: "cognitoUserID",
                        value: "undefined"
                    }
                }
            ]

        // let user = await Auth.currentAuthenticatedUser({ bypassCache: true});
        if (!user) return false;

        if (!this.client || !this.ngClient) this.setClient(user);

        try {
            let response = null;

            for (let check of checkFlow) {
            // checkFlow.some(async (check: any) => {

                let value = this.getStringKeyFromObject(check.value, user);
                if ('lowercase' in check) value = value.toLowerCase();

                response = await this.run(`
                            query ${check.query}($${check.key}: ${check.type}) {
                            ${check.query}(${check.key}: $${check.key}) {
                                items {
                                id
                                cognitoUserID
                                cognitoUsername
                                }
                            }
                            }
                        `,
                    {
                        variables: {
                            [check.key]: value
                        }
                    }
                );

                if (response && response.items.length) {

                    let responseItem = response.items[0];

                    if ('checkResponse' in check) {
                        for (let i in response.items) {
                            let item = response.items[i];
                            if (item[check.checkResponse.key] === check.checkResponse.value) {
                                profile = true;
                                responseItem = response.items[i]
                            }
                        }

                    } else {
                        profile = true;
                    }

                    //update profile with cognito values if it doesn't have it
                    let fields: any = {};
                    if (responseItem.cognitoUserID === 'undefined' || responseItem.cognitoUserID === '' || responseItem.cognitoUserID === null) {
                        fields.cognitoUserID = user.signInUserSession.idToken.payload.sub
                    }
                    if (responseItem.cognitoUsername === 'undefined' || responseItem.cognitoUsername === '' || responseItem.cognitoUsername === null) {
                        fields.cognitoUsername = user.username
                    }

                    if (Object.keys(fields).length !== 0) {
                        fields.id = responseItem.id;
                        await this.updateUserProfile(fields);
                    }
                    break;
                }
                // return profile;
            }
            // });


        } catch (e) {
            this.extensions.callEvent(EventList.ERROR, { message: "Error getting profile for current user" })
        }

        return profile;
    },


    getCurrentNGRProfile: async function(values?: string[] | string, subscribe?: Function): Promise<any> {
        let profile = null,
            defaultValues = ['id'];

        let returnString = this.fullNGRProfileQuery;
        if(values) {
            if(Array.isArray(values)) {
                returnString = [...defaultValues, ...values].join(' ');
            } else if(typeof values === 'string') {
                returnString = values;
            }
        }

        try {
            let response = await this.run(`
                query getCurrentNGRProfile {
                    getCurrentNGRProfile {
                        ${returnString}
                    }
                }
                `,
                {
                    subscribe_keys: values,
                    subscribe: subscribe ? subscribe : null
                }
            );

            if (response && response.length > 0) {
                profile = response[0];
                this.currentNGRProfile = profile;
            }
        } catch (e) {
            console.error('getCurrentNGRProfile', e);
            this.callEvent(EventList.ERROR, { message: "Error getting NGR profile for current user" })
        }

        return profile;
    },

    getCurrentUserProfile: async function(values: string[], subscribe?: Function): Promise<any> {

        let profile = null,
            defaultValues = [];

        let user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        let cognitoID = (user) ? user.signInUserSession.idToken.payload.sub : null;

        if (!cognitoID) return null;

        if (!values.includes('id')) defaultValues.push('id');

        try {
            let response = await this.run(`
                query ngrProfileByCognitoID {
                    ngrProfileByCognitoID(cognitoUserID: "${cognitoID}") {
                        items {
                            ${[...defaultValues, ...values].join(' ')}
                        }
                    }
                }
                `,
                {
                    subscribe_keys: values,
                    subscribe: subscribe ? subscribe : null
                }
            );

            if (response && response.items.length > 0) {
                profile = response.items[0];
            }

        } catch (e) {
            this.callEvent(EventList.ERROR, { message: "Error getting profile for current user" })
        }

        return profile;
    },

    getUserProfile: async function(id: string, values: string[], subscribe?: Function) {
        let pv = '',
            profile = null,
            defaultValues = [];

        if (!values.includes('id')) defaultValues.push('id');

        values = [...defaultValues, ...values];

        values.forEach(v => pv += v + ' ');

        try {
            let response = await this.run(`
          query getProfile {
            getProfile() {
              ${pv}
            }
          }
        `,
                {
                    subscribe_keys: values,
                    subscribe: subscribe ? subscribe : null
                }
            );

            if (response) {
                profile = ('getProfile' in response) ? response.getProfile : response;
            }
        } catch (e) {
            this.callEvent(EventList.ERROR, { message: "Error getting profile for user: " + id })
        }

        return profile;
    },

    updateUserProfile: async function(values: { [key: string]: string }, returnFields?: string[]) {

        let returnString = (returnFields) ? returnFields.join(' ') : 'id';

        let response = await this.run(`
      mutation updateNGRProfile($input: UpdateNGRProfileInput!) {
        updateNGRProfile(input: $input) {
          ${returnString}
        }
      }
    `,
            {
                variables: {
                    input: values
                }
            }
        );

        return response;
    },

    attachProfile: async function(id: string) {
        try {
            return await this.run(`
          mutation attachProfile {
            attachProfile(UserId: "${id}") {
              UserId
              cognitoUserId
              username
            }
          }
        `
            );
        } catch (e) {
            this.callEvent(EventList.ERROR, { message: "Error attaching profile" });
            console.log('Error attaching profile');
            return null;
        }
    },

    createProfileFromCognitoUser: async function(user: any) {
        let date = new Date().toISOString();

        let values: any = {
            ngrState: 'ACTIVE',
            createdAt: date,
            updatedAt: date,
            themePreference: 'AUTO',
            developer: false,
            cognitoUserID: user.signInUserSession.idToken.payload.sub,
            cognitoUsername: user.username,
            email: user.signInUserSession.idToken.payload.email.toLowerCase(),
            firstName: null,
            lastName: null
        }

        let result = await this.run(`
        mutation createNGRProfile($input: CreateNGRProfileInput!) {
          createNGRProfile(input: $input) {
            id
            cognitoUserID
          }
        }
      `,
            {
                variables: {
                    input: values
                }
            }
        );

        return result;

    },

    createProfile: async function(values: { [key: string]: any }) {

        console.log('this create', values);

    },

    openProfile: async function(options?: LightboxOptions) {

        let width: any = 856;
        let height: any = 725;

        if (window.innerWidth < 1032) width = 736;
        if (window.innerWidth < 736) {
            width = "100%";
            height = "100%";
        }

        if (!options) options = {};
        if (!('styles' in options)) options.styles = {};
        if (!('box' in options.styles)) options.styles.box = {};
        if (!('background' in options.styles)) options.styles.background = {
            background: "#000",
            opacity: 0.5
        };

        if (!('width' in options.styles.box)) options.styles.box.width = width;
        if (!('height' in options.styles.box)) options.styles.box.height = height;

        options.styles.box = this.replaceObjectValues(options.styles.box, this);
        options.styles.background = this.replaceObjectValues(options.styles.background, this);

        if (!('module' in options)) {
            console.error('profile module must be specified when opening profile');
        }

        options.isProfile = true;

        return await this.openLightbox(options);
    }

}

export default NGRProfileMethods;