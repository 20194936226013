import { Hub } from 'aws-amplify';
import { PlayerFrame, Observable, Events, EventList } from './types/api.types';
import history from './utils/history';
import baseEvents from './utils/events';
import { detectBrowser } from './utils/browser';
import bridge from './utils/bridge';
import { Link } from 'react-router-dom';
import { MethodBuilder } from './methods';


declare global {
    interface Window { NexgenWindow: any }
}

class API {

    public top: Window = window;
    public mainWindow: Window = this.top;

    public url: string = "";
    public frames: PlayerFrame[] = [];
    public events: Events = baseEvents;
    public RouterLink: any = Link;
    public fullConfig: any = {};
    public config: {[key: string]: any} = {};

    // DEPRECATED: these will be removed after tailwind
    public theme: any = null;
    public muiTheme: any = null;
    // in favor of a generic list
    public themeList: any = {};

    private authUserObject: any = null;
    private userID: any = null;

    private client: any = null;
    private gqlClient: any = null;   // client for the ng-gql-data-layer (which will eventually replace `client`
    private observable: Observable = {};
    private parent: any = null;
    private rootPath: string = '';
    private navPath: string = '';
    private AppConfig: any = null;
    private history: any = history;
    private apiContext: { module: string, id: string } = null;
    private userAgent: string = null;
    private browser: Object = null;
    private os: Object = null;

    private Events: any = null;
    private instanceID: string = null;

    private signalControllers: {[key: string]: any} = {};

    constructor(rootPath: string, AWSConfig: any) {

        Hub.listen('auth', async (data) => {
            // console.log(data.payload)
            switch (data.payload.event) {
                //sign in listeners are done in index.tsx
                case 'signOut':
                    this.saveReportingEvent({
                        activity: "logout"
                    });
                    this.removeQueryParam('forceLogin');
                    this.callEvent(EventList.SIGNOUT, data.payload.data);
                    break;
                case 'signIn_failure':
                    this.parent.setState({ isSSO: false });
                    this.callEvent(EventList.ERROR, data.payload.data);
                    break;
                default:
                    break;
            }
        });

        let { userAgent, browser, os } = detectBrowser();
        this.userAgent = userAgent;
        this.browser = browser;
        this.os = os;

        this.url = window.location.origin;
        this.rootPath = rootPath;
        this.navPath = rootPath;
        this.AppConfig = AWSConfig;

        let methodList: any = MethodBuilder(AWSConfig);

        for(var key in methodList) {
            API.prototype[key] = methodList[key];
        }

    }

    setParent(parent: any) {
        this.parent = parent;
    }

    getFDBridge(ngready?: any) {
        return new bridge(ngready);
    }

    inLegacyApp() {
        return this.parent.props.main.state.inLegacyApp;
    }

}

interface API {
    [key: string]: any
}

export default API;