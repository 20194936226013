import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Link
} from '@material-ui/core';

interface PrivacyPolicyProps {
    open: boolean
    onCancel: Function
    onAccept: Function
    options: any
}

const PrivacyPolicy = (props: PrivacyPolicyProps) => {

    let { open, onCancel, onAccept, options } = props;

    return (
        <Dialog
            open={open}
            onClose={() => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Privacy Policy"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                With your initial log in and each use thereafter, you acknowledge and agree that you have reviewed and accept our <Link href={options.privacyPath} target="_blank" onClick={undefined}>Privacy Policy</Link> and understand our use of user data.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => {
                onCancel();
            }} color="primary">
                Log Out
            </Button>
            <Button onClick={() => {
                onAccept();
            }} color="primary" autoFocus>
                Agree
            </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PrivacyPolicy;