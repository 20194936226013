
import { GenericObject, LightboxOptions, ProxyModuleData } from '../types/api.types';
import { processUserEventGeneric } from '../utils/queryStrings';

const LightboxMethods = {
    openLightbox: async function (options: LightboxOptions) {

        let url = (this.parent.state.token && this.parent.state.token !== '') ? `${options.url}?fdapptoken=${this.parent.state.token}` : options.url;
        
        if (options.module) {
            url = (this.parent.state.token && this.parent.state.token !== '') ? `/${this.AppConfig.moduleIndex[options.module]}/index.html?fdapptoken=${this.parent.state.token}` : `/${this.AppConfig.moduleIndex[options.module]}/index.html`;
        }

        var styles: GenericObject = {};
        var background: GenericObject = {};
        let frames = [...this.parent.state.frames];
        let params: { [key: string]: any } = {};

        this.saveReportingEvent({
            activity: "openLightbox",
            nav_name_path: options.module + "/" + ('id' in options ? options.id : `lightbox-${frames.length}`)
        });

        let currentUserPath = await window.nexgen.getCurrentLocation().pathname;
        let userData = await window.nexgen.getCurrentUser('fdl');

        if (currentUserPath === "") currentUserPath = "home";

        let mutationVariables: any = {
            input: {
                // userId: userData.userID,
                // sessionUserId: userData.userID,
                // sessionId: window.nexgen.authUserObject.attributes.sub ? window.nexgen.authUserObject.attributes.sub : "",
                partition: window.nexgen.getPartition(),
                ngModule: "ngr-player",
                startsAt: new Date(),
                action: "openLightbox",
                navigation: currentUserPath,
                navigationPath: options.module + "/" + ('id' in options ? options.id : `lightbox-${frames.length}`),
                // playerMeta: window.nexgen.os,
                // ngModuleMeta: window.nexgen.history
            },
        };
        
        let upsert_results = await window.nexgen.gqlrun(processUserEventGeneric, {
            variables: mutationVariables,
            // fetchPolicy: 'network-only'
        });
        console.log("upsert_results", upsert_results)

        if ('width' in options) {
            console.warn("Please update lightbox to specify width in styles options")
            if (typeof options.width === 'string') styles.width = options.width;
            else styles.width = options.width + 'px';
        }

        if ('height' in options) {
            console.warn("Please update lightbox to specify height in styles options")
            if (typeof options.height === 'string') styles.height = options.height;
            else styles.height = options.height + 'px';
        }

        if ('overlay' in options) {
            console.warn("Please update lightbox to specify background in styles options")
            background.background = options.overlay;
        }
        if ('overlayOpacity' in options) {
            console.warn("Please update lightbox to specify background opacity in styles options")
            background.opacity = options.overlayOpacity;
        }

        if ('params' in options) {
            params = options.params;
        }

        if (!('height' in styles)) styles.height = "80%";
        if (!('width' in styles)) styles.width = "80%";


        if ('styles' in options) {
            styles = {...styles, ...options.styles.box};
            background = {...background, ...options.styles.background};
        }

        params = {...params, maximized: false}
        
        if (url.includes('pdf')) {
            if (!options.options) options.options = {};
            
            options.options.closeButton = false;
        };

        frames.push({
            id: 'id' in options ? options.id : `lightbox-${frames.length}`,
            url,
            styles,
            initialStyles: styles,
            name: options.module,
            backgroundStyles: background,
            params,
            options: options.options
        });

        return this.parent.setState({ frames: frames });
    },
    

    maximizeLightbox: function(id?: string) {
        let moduleData: ProxyModuleData = this.getProxyModuleData();
        if(!id) id = moduleData.id;
        let frames = [...this.parent.state.frames];
        for(let f in frames) {
            if(frames[f].id === id) {
                frames[f].styles = {...frames[f].styles, width: "100%", height: "100%"};
                frames[f].params = {...frames[f].params, maximized: true}
            }
        }
        this.parent.setState({ frames: frames });
    },
    minimizeLightbox: function(id?: string) {
        let moduleData: ProxyModuleData = this.getProxyModuleData();
        if(!id) id = moduleData.id;
        let frames = this.parent.state.frames;
        for(let f in frames) {
            if(frames[f].id === id) {
                frames[f].styles = frames[f].initialStyles;
                frames[f].params = {...frames[f].params, maximized: false}
            }
        }
        this.parent.setState({ frames: frames });
    },

    hideLightbox: async function() {
        let lb_frame = this.getProxyWindow(),
            lb_parent = lb_frame.parentElement,
            lb_bg = lb_parent.previousElementSibling;

        lb_parent.classList.add("hidden-lightbox");
        lb_bg.classList.add("hidden-lightbox");
    },

    showLightbox: async function() {

        let lb_frame = this.getProxyWindow(),
            lb_parent = lb_frame.parentElement,
            lb_bg = lb_parent.previousElementSibling;

        lb_parent.classList.remove("hidden-lightbox");
        lb_bg.classList.remove("hidden-lightbox");
    },

    closeLightbox: async function(id?: string) {
        let moduleData: ProxyModuleData = this.getProxyModuleData();
        if(!id) id = moduleData.id;
        let frames = this.parent.state.frames.filter((f: any) => f.id !== id);

        this.saveReportingEvent({
            activity: "closeLightbox",
            nav_name_path: moduleData.name + '/' + id
        });

        let currentUserPath = await window.nexgen.getCurrentLocation().pathname;
        let userData = await window.nexgen.getCurrentUser('fdl');

        if (currentUserPath === "") currentUserPath = "home";

        let mutationVariables: any = {
            input: {
                // userId: userData.userID,
                // sessionUserId: userData.userID,
                // sessionId: window.nexgen.authUserObject.attributes.sub ? window.nexgen.authUserObject.attributes.sub : "",
                partition: window.nexgen.getPartition(),
                ngModule: "ngr-player",
                startsAt: new Date(),
                action: "closeLightbox",
                navigation: currentUserPath,
                navigationPath: moduleData.name + '/' + id,
                // playerMeta: window.nexgen.os,
                // ngModuleMeta: window.nexgen.history
            },
        };
        
        let upsert_results = await window.nexgen.gqlrun(processUserEventGeneric, {
            variables: mutationVariables,
            // fetchPolicy: 'network-only'
        });
        console.log("upsert_results", upsert_results)

        return this.parent.setState({ frames: frames });
    },

    closeAllLightboxes: async function() {
        return this.parent.setState({ frames: [] });
    }
}

export default LightboxMethods;
