import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Link
} from '@material-ui/core';

interface SessionControlProps {
    open: boolean
    onEndAll: Function
    onSignOut: Function
    options: any
}


const SessionControl = (props: SessionControlProps) => {

    let { open, onEndAll, onSignOut, options } = props;

    return (
        <Dialog
            open={open}
            onClose={() => { 
                onSignOut()
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Session Limit Reached"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    It looks like you've reached the maximum number of open sessions.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    onEndAll();
                }} color="primary">
                    End all sessions
                </Button>
                <Button onClick={() => {
                    onSignOut();
                }} color="primary" autoFocus>
                    Log out
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SessionControl;